import React, { useState, useRef, useEffect } from "react";
import BackgroundImage from "../../components/BackgroundImage";
import { Alert, Box, Button, Collapse, Divider, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import HelpIcon from '@mui/icons-material/Help';
import { Link, useNavigate } from "react-router-dom";

type Focus = 1 | 2 | 3 | 4;

interface Props {
    weddingId?: string
    loginGroup: (code: string) => Promise<boolean>
}

export const Login: React.FC<Props> = ({weddingId, loginGroup}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"))
    const [focus, setFocus] = useState<Focus>(1);
    const [values, setValues] = useState<string[]>(["", "", "", ""]);
    const inputRefs = useRef<Array<HTMLInputElement | null>>([]);
    const [invalidCredentials, setInvalidCredentials] = useState<boolean>(false)
    const navigate = useNavigate()

    const handleClear = ()=>{
        setValues(["", "", "", ""])
        setFocus(1)
    }

    const login = async()=>{
        if (!weddingId) return
        if (values[0] && values[1] && values[2] && values[3]){
            const entryCode: string = values.join("")
            const res = await loginGroup(entryCode)
            if (!res){
                setInvalidCredentials(true)
                handleClear()
            }
        }
    }

    const handleKeyDown = (
        event: React.KeyboardEvent<HTMLInputElement | HTMLDivElement>,
        currentIndex: Focus
    ) => {
        switch (event.key) {
            case "Backspace":
                event.preventDefault();
                if (!values[currentIndex - 1] && currentIndex > 1) {
                    setFocus(currentIndex - 1 as Focus);
                }
                setValues((prev) => {
                    const updated = [...prev];
                    updated[currentIndex - 1] = "";
                    return updated;
                });
                break;
            case "ArrowLeft":
                if (currentIndex > 1) {
                    setFocus(currentIndex - 1 as Focus);
                }
                break;
            case "ArrowRight":
                if (currentIndex < 4) {
                    setFocus(currentIndex + 1 as Focus);
                }
                break;
            default:
                break;
        }
    };

    const handleChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        currentIndex: Focus
    ) => {
        const value = event.target.value;
        if (value.length > 1) return;
        const sanitizedValue = value.replace(/[^a-zA-Z0-9]/g, "").toUpperCase();
        setValues((prev) => {
            const updated = [...prev];
            updated[currentIndex - 1] = sanitizedValue;
            return updated;
        });
        if (sanitizedValue && currentIndex < 4) {
            setFocus(currentIndex + 1 as Focus);
        }
    };

    const handleClick = (index: Focus) => {
        setFocus(index);
    };

    useEffect(() => {
        if (inputRefs.current[focus - 1]) {
            inputRefs.current[focus - 1]?.focus();
        }
    }, [focus]);

    useEffect(()=>{
        login()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[values])

    return (
        <Box position={"relative"} paddingBottom={"90px"} height={"100%"} width={"100%"}>
            <Button onClick={()=>navigate("/weddings")} variant="contained" sx={{position: "absolute", bottom: "10px", right: "10px"}}>Hochzeits-Login</Button>
            <BackgroundImage imageUrl="/images/wallpaper2.jpg" />
            <Box 
                display={"flex"} flexDirection={"column"} alignItems={"center"} justifyContent={"start"}
                width={"100%"} maxWidth={"calc(100% - 20px)"} marginX={"auto"} 
                // height={"90dvh"}           
            >
                <Box width={"100%"} display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"} mt={"2em"}>
                    <Box width={"100%"} display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"} my={"1.5em"}>
                        <Box height={isMobile ? "4rem" : "6rem"} width={isMobile ? "4rem" : "6rem"}>
                            <img height={"100%"} width={"100%"} src="/images/Logo.png"/>
                        </Box>
                    </Box>
                
                    <Typography margin={"0.2em 0 0 0"} variant="h1" textAlign={"center"} fontSize={isTablet ? "3rem" : isMobile ? '2rem' : "4rem"}
                        fontFamily={theme.palette.homepage.fontFamily} color={theme.palette.textColor.title2}>
                        Willkommen auf der Website!
                    </Typography>
                    <Typography variant="h6" fontFamily={theme.palette.homepage.fontFamily} textAlign={"center"}
                        color={theme.palette.textColor.title2} fontSize={isMobile ? '0.9rem' : "1.25rem"} marginX={isMobile ? "10px" : 0}>
                        Bitte geben Sie Ihren Einladungscode ein
                    </Typography>
                </Box>
                <Box 
                    display={"flex"} flexDirection={"column"} alignItems={"center"} justifyContent={"center"}
                    maxWidth={"25em"} padding={isMobile ? "25px" : "50px"} textAlign={"center"} borderRadius={"10px"} 
                    sx={{backgroundColor: "#ffffff"}} mt={"2em"}
                >
                    <Typography variant="body2" color="textSecondary" marginBottom={3} width={250}>
                        Ihren Code finden Sie auf der Einladung. Stellen Sie sicher, dass Sie an der richtigen Adresse sind
                    </Typography>
                    <Collapse in={invalidCredentials}>
                        <Alert severity="warning" sx={{ mb: 2 }}>Der eingegebene Code ist falsch</Alert>
                    </Collapse>
                    <Box display={"inline-flex"}>
                        {[1, 2, 3, 4].map((i) => (
                            <TextField
                                key={i}
                                required
                                variant="outlined"
                                style={{ width: "50px", marginRight: "10px" }}
                                inputRef={(el) => (inputRefs.current[i - 1] = el)}
                                value={values[i - 1]}
                                onKeyDown={(e) => handleKeyDown(e, i as Focus)}
                                onChange={(e) => handleChange(e, i as Focus)}
                                onClick={() => handleClick(i as Focus)}
                                slotProps={{ 
                                    htmlInput: {
                                        maxLength: 1,
                                        style: { textAlign: "center", fontSize: "20px" },
                                    },
                                    input: {
                                        autoComplete: "off",
                                    },
                                }}
                            />
                        ))}
                    </Box>
                    <Divider sx={{width: "100%", my: 2}}/>
                    <Button 
                        component={Link}
                        to="/help"
                        variant="text" 
                        size="small" 
                        sx={{display: "inline-flex", gap: 1, alignItems: "center"}}
                    >
                            <HelpIcon /> Ich komme nicht rein
                    </Button>
                </Box>
            </Box>
            
        </Box>
    );
};
