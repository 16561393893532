import React, { useEffect, useState } from "react"
import { Box, Button, CircularProgress, FormControl, Grid2, InputLabel, MenuItem, Select, TextField, Typography, useMediaQuery, useTheme } from "@mui/material"
import { fileToBase64, blobToUrl, base64ToBlob } from "../../utils/blobImage";
import { FontFamilyList, FontFamilyTitle, Wedding } from "../../utils/Wedding";
import { useWeddingAdmin } from "../../context/WeddingAdminProvider";
import { ResponsiblePaper } from "../wedding/components/ResponsiblePaper";
import { PictureWithName } from "../wedding/components/PictureWithName";
import imageCompression from "browser-image-compression";
import dayjs, { Dayjs } from "dayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useNavigate } from "react-router-dom";
import ErrorsMessage from "../../utils/ErrorsMessage";
import UploadIcon from '@mui/icons-material/Upload';
import { BLOCKED_DISPLAY_URLS, DISPLAY_URL_REGEX } from "../../utils/NotPermittedUrl";

export const MainSettings: React.FC<any> = ()=>{
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const { wedding, weddingId, alertSnack, refetch } = useWeddingAdmin();
    const [name, setName] = useState<string>(wedding?.name || '');
    const [displayUrl, setDisplayUrl] = useState<string>(wedding?.displayUrl || '');
    const [address, setAddress] = useState<string>(wedding?.weddingAddress || '');
    const [weddingDate, setWeddingDate] = useState<Dayjs | null>(wedding?.weddingDate ? dayjs(wedding.weddingDate) : null);
    const [confirmDate, setConfirmDate] = useState<Dayjs | null>(wedding?.confirmBeforeDate ? dayjs(wedding.confirmBeforeDate) : null);
    const [pictureUrl, setPictureUrl] = useState(`/api/weddings/${weddingId}/background`);
    const [picture, setPicture] = useState<string | null>(null);
    const [fontFamilyTitle, setFontFamilyTitle] = useState<FontFamilyTitle>(`${wedding?.fontFamilyTitle || "Bilbo Swash Caps"}`);
    const [errors, setErrors] = useState({name: false,displayUrl: false,});
    const [loadingCompress, setLoadingCompress] = useState<boolean>(false)
    const navigate = useNavigate()

    useEffect(()=>{
        picture && setPictureUrl(blobToUrl(base64ToBlob(picture)) || '')
    },[picture])

    useEffect(()=>{
        setName(wedding?.name || '')
        setDisplayUrl(wedding?.displayUrl || '')
        setAddress(wedding?.weddingAddress || '')
        setWeddingDate(wedding?.weddingDate ? dayjs(wedding.weddingDate) : null)
        setConfirmDate(wedding?.confirmBeforeDate ? dayjs(wedding.confirmBeforeDate) : null)
        setPictureUrl(`/api/weddings/${weddingId}/background`)
        setFontFamilyTitle(`${wedding?.fontFamilyTitle || "Bilbo Swash Caps"}`)
    },[wedding])

    const updateGeneral = ()=>{
        const data: Partial<Wedding> = {
            id: wedding?.id,
            name,
            displayUrl,
            fontFamilyTitle,
            backgroundPicture: picture,
            weddingDate,
            confirmBeforeDate: confirmDate,
            weddingAddress: address
        }
        fetch(`/api/weddings/${weddingId}/general`, {
                method: "PUT",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
        }).then((response)=>{
            if (response.ok) {
                alertSnack({text: "Success", type: "success"})
                
                // If url changes, redirect to correct url
                if (wedding?.displayUrl !== displayUrl){
                    navigate(`/weddings/${displayUrl}`)
                    return
                }
                
                refetch();
                return
            }
            if (ErrorsMessage.hasOwnProperty(response.status)) {
                alertSnack({ text: ErrorsMessage[response.status], type: "warning" });
                return;
            }
            alertSnack({text: "Error", type: "error"})
        }).catch((err)=>{
            alertSnack({text: ErrorsMessage["400"] , type: "error"})
        })
    }

    const validateFields = (): boolean => {
        let valid = true
        const newErrors: any = {
            name: "",
            displayUrl: "",
        };
    
        if (!name.trim()) {
            newErrors.name = "Please enter a name";
            valid = false;
        }
    
        if (!displayUrl.trim()) {
            newErrors.displayUrl = "Please enter a display url";
            valid = false;
        } else {
            const trimmedUrl = displayUrl.trim().toLowerCase();
    
            if (!DISPLAY_URL_REGEX.test(trimmedUrl)) {
                newErrors.displayUrl = "The display URL must contain only lowercase letters, numbers, and hyphens, and be 3 to 30 characters long.";
                valid = false;
            }
    
            if (BLOCKED_DISPLAY_URLS.includes(trimmedUrl)) {
                newErrors.displayUrl = "The provided display URL is blocked.";
                valid = false;
            }
        }    
        setErrors(newErrors)
        return valid
    };

    const handleUpload = async (e: any) => {
        const file = e.target.files?.[0];
        if (!file) return;
        setLoadingCompress(true)

        try {
            const options = {
                maxSizeMB: 0.5, 
                useWebWorker: true,
            };
            const compressedFile = await imageCompression(file, options);
            const base64String = await fileToBase64(compressedFile);
            setPicture(base64String);
        } catch (error) {
            alertSnack({text: "Error processing image", type: "error"})

        } finally {
            setLoadingCompress(false)
        }
    };

    const handleSave = ()=>{
        if (!validateFields()) return
        updateGeneral()
    }

    return (
        <>
        <ResponsiblePaper autoMargin fullWidth widthReduction={isMobile ? '1em' : '2em'} elevation={3} 
            style={{ padding: isMobile ? '10px' : '15px', marginTop: "2em", maxWidth: "800px"  }}>
            <Grid2 container spacing={3}>
                <Grid2 size={12}>
                    <TextField
                        fullWidth
                        label="Name"
                        name="name"
                        value={name}
                        onChange={(e)=>{
                            setName(e.target.value)
                            errors.name && setErrors(prev => ({...prev, name: false}))
                        }}
                        error={errors.name}
                        required
                    />
                </Grid2>
                <Grid2 size={12}>
                    <TextField
                        fullWidth
                        label="Display URL"
                        name="displayUrl"
                        value={displayUrl}
                        error={errors.displayUrl}
                        helperText={errors.displayUrl || ' '}
                        onChange={(e)=>{
                            setDisplayUrl(e.target.value)
                            errors.name && setErrors(prev => ({...prev, displayUrl: false}))
                        }}
                    />
                </Grid2>
                <Grid2 size={12} display={"inline-flex"} flexWrap={"wrap"} gap={2}>
                    <FormControl sx={{ my: 1, minWidth: 120 }}>
                        <InputLabel id="demo-simple-select-helper-label">Font</InputLabel>
                        <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            value={fontFamilyTitle}
                            label="Font"
                            onChange={(e)=>setFontFamilyTitle(e.target.value as FontFamilyTitle)}
                        >
                            {FontFamilyList.map((f, i)=> {
                                return <MenuItem key={i} value={f}>{f}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                    <DatePicker 
                        sx={{mt: 1}}
                        label="Wedding Date"
                        value={weddingDate} 
                        onChange={(newValue) => setWeddingDate(newValue)}
                        format="DD.MM.YYYY"
                    />
                    <DatePicker 
                        sx={{mt: 1}}
                        label="Confirm before *Date*"
                        value={confirmDate}
                        onChange={(newValue) => setConfirmDate(newValue)}
                        format="DD.MM.YYYY"
                    />
                    <TextField
                        fullWidth
                        label="Address"
                        name="Address"
                        value={address}
                        onChange={(e)=>setAddress(e.target.value)}
                    />
                    <Grid2 size={12} display={"inline-flex"} gap={3} my={2} alignItems={"center"}>
                        <Button variant="outlined" component="label">
                            <UploadIcon />
                            Upload Background Picture
                            <input
                                type="file"
                                hidden
                                onChange={handleUpload}
                            />
                        </Button>
                        {loadingCompress && 
                            <Box display={"inline-flex"} alignItems={"center"} gap={2}>
                                <CircularProgress sx={{height: '20px !important', width: '20px !important'}}/>
                                <Typography variant="body2">Compressing image...</Typography>
                            </Box>
                        }
                    </Grid2>
                </Grid2>
                <Grid2 size={12}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSave}
                    >
                        Save
                    </Button>
                </Grid2>
            </Grid2>
        </ResponsiblePaper>
        <ResponsiblePaper fullWidth autoMargin widthReduction={isMobile ? "1rem" : "2rem"} 
            style={{maxWidth: '1000px', marginTop: "2em", display: "flex", flexDirection: "column", alignItems: "center"}}>
            <Typography variant="h6">Live preview:</Typography>
            <PictureWithName url={pictureUrl} title={wedding?.name} fontTitle={fontFamilyTitle} loading={loadingCompress} date={weddingDate}/>
        </ResponsiblePaper>
        </>
    )
}