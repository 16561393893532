import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import React from "react";
import { AgeOption, AgeType, Guest } from "../../../utils/Wedding";

interface IProps {
    onChange: (value: AgeType, id: number) => void;
    guest: Guest;
    options: AgeOption[]
}

export const AgeOptionsSelect: React.FC<IProps> = ({onChange, guest, options})=>{



    return (
        <FormControl sx={{ m: 1, width: "12rem"}} size="small">
            <InputLabel id={`ageinput-${guest.id!}`}>Altersgruppe</InputLabel>
            <Select
                label="Altersgruppe"
                value={guest.selectedAgeOption?.type}
                onChange={(e)=>onChange(e.target.value as AgeType, guest.id!)}
                variant="outlined"
                sx={{ width: '100%' }}
                labelId={`ageinput-${guest.id!}`}
                size='small'
            >
                {options.map((option, index)=>{
                    return <MenuItem key={index} value={option.type}>{option.type} ({option.description})</MenuItem>
                })}
                <MenuItem key={"99"} value={undefined}>--</MenuItem>
            </Select>
        </FormControl>
    )
}