import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import styles from "../WeddingHomepage.module.css"
import { FontFamilyTitle } from "../../../utils/Wedding";
import { useState } from "react";
import dayjs, { Dayjs } from "dayjs";

interface IProps {
    url?: string;
    title?: string;
    fontTitle: FontFamilyTitle;
    loading?: boolean;
    date?: Dayjs | null
}

export const PictureWithName: React.FC<IProps> = ({url, title, fontTitle, loading = false, date})=>{
    const theme = useTheme();
    const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"))
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const [loaded, setLoaded] = useState(false);

    return (
        <div 
            className={styles.backgroundPictureContainer}
            style={{
                margin: isMobile ? '10px' : "0 60px",
                width: loading ? "100%" : "inherit",
                height: loading ? "100%" : "inherit",
                minHeight: loading ? "100%" : "inherit"
            }}
        >   
            {(loading || !loaded) && <Box width={"100%"} height={"70dvh"} />}
            
            <img className={styles.backgroundPicture} 
                style={{
                    border: isMobile ? '10px solid white' : '20px solid white', 
                    display: loaded ? 'inherit' : 'none',
                }} 
                src={url} alt="wedding"
                onLoad={() => setLoaded(true)}
            />
            
            <span className="spanTitleWedding">
                <Typography 
                    variant={isMobile ? "h3" : isTablet ? 'h2' : 'h1'} 
                    textAlign={'center'} 
                    fontFamily={`${fontTitle}, cursive`}
                >
                    {title || 'Kein Titel'}
                </Typography>
                <Typography 
                    variant={isMobile ? "body2" : isTablet ? 'body1' : 'h4'} 
                    textAlign={'center'} 
                    fontFamily={`${fontTitle}, cursive`}
                >
                    {date ? dayjs(date).format("DD.MM.YYYY") : "Kein Titel"}
                </Typography>
            </span>
        </div>
    )
}