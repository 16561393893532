import { createContext, ReactNode, useContext, useEffect, useState } from "react"
import { useParams, useSearchParams } from "react-router-dom";
import { AgeType, Wedding } from "../utils/Wedding";
import { useAuth } from "./AuthProvider";
import { Login } from "../pages/groupUser/Login";
import { PublicLoadingPage } from "../components/PublicLoadingPage";
import { PublicErrorPage } from "../components/PublicErrorPage";
import { PublicNotFoundPage } from "../components/PublicNotFoundPage";
import { SnackbarHomepage } from "../components/SnackbarHomepage";

interface IWeddingContext {
    wedding: Wedding | null;
    isLoading: boolean;
    error: any;
    weddingId?: string;
    loginGroup: (code: string | undefined)=> Promise<boolean>;
    toggleWillAttend: (guestId: number)=> void;
    setWedding: (wedding: Wedding | null)=>void;
    selectAgeOption: (groupId: number, guestId: number, ageType: AgeType)=>void;
    alertSnack: ({ text, type }: Partial<SnackbarHomepage>) => void;

}

const weddingContext = createContext<IWeddingContext | null>(null)

export const useWedding = () =>{
    const context = useContext(weddingContext);
    if (!context) {
        throw new Error("useWeddingProvider must be used within a WeddingProvider");
    }
    return context;
}

interface IProps {
    children: ReactNode;
}

export const WeddingProvider: React.FC<IProps> = ({children})=>{
    const { weddingId } = useParams()
    const [searchParams, setSearchParams] = useSearchParams()
    const { groupUser, loginWithGroup, isLoading: isLoadingUser, user, logout } = useAuth()
    const [wedding, setWedding] = useState<Wedding | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<any>(null);
    const [notFoundFromLogin, setNotFoundFromLogin] = useState<boolean>(false)
    const [snack, setSnack] = useState<Partial<SnackbarHomepage>>({ open: false, text: "", type: "success" })

    const entryCode = searchParams.get("code");
    
    const alertSnack = ({ text, type }: Partial<SnackbarHomepage>) => {
        setSnack({
            open: true,
            text: text!,
            type: type!
        })
    }

    const clearSnack = ()=>{
        setSnack({
            open: false,
            text: "",
            type: "success"
        })
    }

    const loginGroup = async(code: string | undefined): Promise<boolean>=>{
        if (!code || !weddingId || groupUser) return false

        const response = await loginWithGroup(code, weddingId)
        if (response.success){
            searchParams.delete("code")
            setSearchParams(searchParams)
            // refetch()
            return true
        }
        
        // If unauthorized, return error
        // If not found, render NotFound page before return something
        if (response.error === "UNAUTHORIZED"){
            return false
        }
        setNotFoundFromLogin(true)
        return false
    }

    // If access with a normal user, logout...
    useEffect(()=>{
        if (user){
            logout()
            setIsLoading(false)
        }
    },[user])

    // Logged in = do nothing
    // entry code is on queryString, login automatic
    useEffect(()=>{
        if (groupUser) return

        // Sign in with entry code
        if (entryCode && weddingId){
            loginGroup(entryCode)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[entryCode, weddingId, groupUser])
    
    const fetchWedding = async()=>{
        setIsLoading(true)
        try {
            const endpoint = `/api/weddings/${weddingId}`;
            const response = await fetch(endpoint, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            if (!response.ok) { 
                throw Error(response.status.toString());
            } 
            const data: Wedding = await response.json();
            
            let blob;
            const responsePicture = await fetch(`/api/weddings/${weddingId}/background`, { method: "GET" });
            if (responsePicture.ok) {
                blob = await responsePicture.blob();
            } else {
                const responseBrokenPicture = await fetch("/images/brokenbgpicture.jpg", { method: "GET" });
                if (responseBrokenPicture.ok) {
                    blob = await responseBrokenPicture.blob();
                } else {
                    throw new Error("Invalid background picture");
                } 
            }

            setWedding({...data, backgroundPicture: URL.createObjectURL(blob)});
                
        } catch(err) {
            
            setIsLoading(false);
            if (err instanceof Error) {
                setError(err.message || 'An unknown error occurred');
            } else {
                setError('An unknown error occurred');
            }
            alertSnack({ type: "error", text: "Laden Fehler: " + err })
        } finally {
            setIsLoading(false);
        }
    }

    // Fetch wedding after login with groupUser is successful
    // Show login page if not login in, then fetch wedding
    useEffect(() => {
        if (weddingId && groupUser && !isLoadingUser){
            fetchWedding()
        } else {
            setIsLoading(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [weddingId, groupUser, isLoadingUser])

    const toggleWillAttend = (guestId: number)=>{
        const groupId = wedding?.guestGroup?.id

        if (!groupId) return
        const guest = wedding?.guestGroup?.guests?.find(g => g.id === guestId)

        if (!guest) return

        fetch(`/api/weddings/${weddingId}/groups/${groupId}/guests/${guestId}/willAttend/toggle`, {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((response)=>{
            if (response.ok){
                setWedding(prevWedding => {
                    if (!prevWedding || !prevWedding.guestGroup) return prevWedding;
            
                    const updatedGuestGroup = {
                        ...prevWedding.guestGroup,
                        guests: prevWedding.guestGroup.guests?.map(guest =>
                            guest.id === guestId ? { ...guest, willAttend: !guest.willAttend } : guest
                        )
                    };
            
                    return {
                        ...prevWedding,
                        guestGroup: updatedGuestGroup
                    };
                });
                return
            }
            alertSnack({ type: "warning", text: "Fehler" })
        }).catch(err => {
            alertSnack({ type: "error", text: "Fehler: " + err })
        })
    }

    const selectAgeOption = (groupId: number, guestId: number, ageType: AgeType)=>{
        fetch(`/api/weddings/${weddingId}/groups/${groupId}/guests/${guestId}/selectageoption`, {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ ageType })
        }).then((response)=>{
            if (response.ok){
                setWedding(prevWedding => {
                    if (!prevWedding || !prevWedding.guestGroup) return prevWedding;
                    
                    const updatedGuestGroup = {
                        ...prevWedding.guestGroup,
                        guests: prevWedding.guestGroup.guests?.map((guest) =>{
                            const newSelectedAgeOption = prevWedding.ageOptions.find(a => a.type === ageType)
                            return guest.id === guestId ? { ...guest, selectedAgeOption: newSelectedAgeOption} : guest
                        })
                    };
            
                    return {
                        ...prevWedding,
                        guestGroup: updatedGuestGroup
                    };
                });
                return
            }
            alertSnack({ type: "warning", text: "Fehler" })
        }).catch(err => {
            alertSnack({ type: "error", text: "Fehler: " + err })
        })
    }

    const values: IWeddingContext = {
        wedding,
        isLoading,
        error,
        weddingId,
        loginGroup,
        toggleWillAttend,
        setWedding,
        selectAgeOption,
        alertSnack
    }

    if (isLoading || isLoadingUser) return <PublicLoadingPage />
    if (notFoundFromLogin) return <PublicNotFoundPage weddingId={weddingId}/>
    if (!groupUser) return <Login weddingId={weddingId} loginGroup={loginGroup}/>
    if (error) return <PublicErrorPage error={error?.toString() || error}/>
    if (!wedding && error) return <PublicNotFoundPage weddingId={weddingId}/>
    if (!wedding) return <PublicLoadingPage />

    return <weddingContext.Provider value={values}>
        <SnackbarHomepage open={snack.open!} text={snack.text!} type={snack.type!} onAutoClose={clearSnack}/>
        {children}
    </weddingContext.Provider>
}