import {  Avatar, CSSObject, Divider, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem, styled, Theme, Typography, useMediaQuery, useTheme } from "@mui/material"
import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
import { useWeddingAdmin } from "../context/WeddingAdminProvider";
import { Link } from "react-router-dom";
import AddIcon from '@mui/icons-material/Add';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MarkAsUnreadIcon from '@mui/icons-material/MarkAsUnread';
import SegmentIcon from '@mui/icons-material/Segment';
import DashboardIcon from '@mui/icons-material/Dashboard';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import SportsScoreIcon from '@mui/icons-material/SportsScore';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import MenuIcon from '@mui/icons-material/Menu';
import EscalatorWarningIcon from '@mui/icons-material/EscalatorWarning';
import { useAuth } from "../context/AuthProvider";
import MuiDrawer from '@mui/material/Drawer';
import CheckroomIcon from '@mui/icons-material/Checkroom';
import TheaterComedyIcon from '@mui/icons-material/TheaterComedy';
import HotelIcon from '@mui/icons-material/Hotel';
import TimelineIcon from '@mui/icons-material/Timeline';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import ListIcon from '@mui/icons-material/List';

type Items = {
    label: string;
    endingUrl: string;
    icon: JSX.Element;
}

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
    });

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
    });

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        variants: [
            {
                props: ({ open }) => open,
                    style: {
                    ...openedMixin(theme),
                    '& .MuiDrawer-paper': openedMixin(theme),
                },
            },
            {
                props: ({ open }) => !open,
                    style: {
                    ...closedMixin(theme),
                    '& .MuiDrawer-paper': closedMixin(theme),
                },
            },
        ],
    }),
);

interface IProps {
    open: boolean
    onToggle: ()=>void;
}

export const AdminSidebar: React.FC<IProps> = ({ open, onToggle })=>{
    const { allWeddings, wedding, weddingId } = useWeddingAdmin()
    const { logout } = useAuth()
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const navigate = useNavigate()
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [avatarSrc, setAvatarSrc] = useState<string>(`/api/weddings/${wedding?.displayUrl}/background`)

    useEffect(()=>{
        // Update picture onChange, on sidebar
        setAvatarSrc(`/api/weddings/${wedding?.displayUrl}/background?timestamp=${Date.now()}`); // To prevent Cache
    },[wedding])

    const openListWeddings = Boolean(anchorEl);
    const handleClickOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(()=>{
        isMobile && open && onToggle()
    },[isMobile])

    const items: Items[] = [
        {label: "Dashboard", endingUrl: "", icon: <DashboardIcon />},
        {label: "Main Settings", endingUrl: "settings", icon: <SegmentIcon />},
        {label: "Welcome Text", endingUrl: "description", icon: <SportsScoreIcon />},
        {label: "Infos/Timeline", endingUrl: "timeline", icon: <TimelineIcon />},
        {label: "Arrival", endingUrl: "arrival", icon: <DirectionsCarIcon />},
        {label: "Overnight", endingUrl: "overnight", icon: <HotelIcon />},
        {label: "Dresscode", endingUrl: "dresscode", icon: <CheckroomIcon />},
        {label: "Invitations", endingUrl: "invitations", icon: <MarkAsUnreadIcon />},
        {label: "Age Categories", endingUrl: "agecategories", icon: <EscalatorWarningIcon />},
        {label: "Performances", endingUrl: "performances", icon: <TheaterComedyIcon />},
        {label: "Gift List", endingUrl: "giftlist", icon: <CardGiftcardIcon />},
        {label: "Contribution Lists", endingUrl: "lists", icon: <ListIcon />},
    ]


    return (
        <>
            <Drawer
                variant="permanent"
                anchor="left"
                open={open}
            >
                <DrawerHeader>
                    <IconButton onClick={onToggle} sx={{mr: isMobile ? 0 : 0.5}}>
                        {open ? <MenuOpenIcon /> : <MenuIcon />}
                    </IconButton> 
                </DrawerHeader>
                <List >
                    <ListItem disablePadding sx={{paddingLeft: isMobile ? 0 : 0.5}}>
                        <ListItemButton sx={{justifyContent: "space-between"}} onClick={handleClickOpenMenu}>
                            <ListItemIcon>
                                <Avatar sx={{ width: 24, height: 24 }} src={avatarSrc}/>
                            </ListItemIcon>
                            
                            <ListItemText><Typography variant="body2" noWrap>{wedding?.name}</Typography></ListItemText>
                            <KeyboardArrowDownIcon />
                            {/* <KeyboardArrowDownIcon /> */}
                        </ListItemButton>
                    </ListItem>
                    {items.map((i, index)=>{
                        return (
                            <ListItem key={index} disablePadding sx={{paddingLeft: isMobile ? 0 : 0.5}}>
                                <ListItemButton onClick={()=> navigate(`/weddings/${wedding?.displayUrl}/${i.endingUrl}`)}>
                                    <ListItemIcon>
                                        {i.icon}
                                    </ListItemIcon>
                                    <ListItemText primary={i.label}/>
                                </ListItemButton>
                            </ListItem>
                        )
                    })}
                    <Divider sx={{margin: "auto 0 "}}/>
                    <ListItem disablePadding sx={{paddingLeft: isMobile ? 0 : 0.5}}>
                        <ListItemButton sx={{justifyContent: "space-between"}} onClick={logout}>
                            <ListItemIcon>
                                <LogoutIcon />
                            </ListItemIcon>
                            <ListItemText primary={"Logout"}/>
                        </ListItemButton>
                    </ListItem>
                </List>
                
            </Drawer>
            <Menu
                anchorEl={anchorEl}
                open={openListWeddings}
                onClose={handleClose}
            >   
                {allWeddings?.filter(wd => wd.id !== wedding?.id).map((w, i)=>{
                    return (
                        <MenuItem key={i} component={Link} to={`/weddings/${w.displayUrl}`} onClick={handleClose} disableRipple>
                            <Avatar sx={{ width: 24, height: 24, mr: 1.5 }} src={`/api/weddings/${w?.displayUrl}/background`}/>
                            <Typography variant="body2" noWrap>{w?.name}</Typography>
                        </MenuItem>
                    )    
                })}
                {allWeddings?.filter(wd => wd.id !== wedding?.id).length > 0 && <Divider />}
                <MenuItem component={Link} to={"/weddings/create"} onClick={handleClose} disableRipple>
                
                    <AddIcon />
                    Create new wedding
                </MenuItem>
            </Menu>
            
        </>
    )
}