import DOMPurify from 'dompurify';

const permittedTags = {
    ALLOWED_TAGS: [
        "h1","h2","h3","h4","h5","h6","p","span","div","blockquote","code",
        "pre","sub","sup","ul","ol","li","video","img","a","strong","br","em", "u"
    ],
    ALLOWED_ATTR: ["class","style","align","href","src","title","alt","width","height"],
}

const Sanitize = (value?: string | null)=>{
    return DOMPurify.sanitize(value || "", permittedTags).replace(/&nbsp;/g, ' ');;
}

export default Sanitize
