import React, { useEffect, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { TextField, Button, Box, Typography, useMediaQuery, useTheme, Alert, Collapse } from '@mui/material';
import BackgroundImage from '../../components/BackgroundImage';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthProvider';

interface LoginFormInputs {
    username: string;
    password: string;
}

export const Login: React.FC = () => {
    const { register, handleSubmit, formState: { errors } } = useForm<LoginFormInputs>();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const { loginWithUser, isLoading, user } = useAuth();
    const navigate = useNavigate();
    const [error, setError] = useState<boolean>(false)

    useEffect(() => {
        if (user && !isLoading) {
            navigate("/weddings");
        }
    }, [navigate, user, isLoading]);

    const handleLogIn = async (data: LoginFormInputs) => {
        setError(false)
        const response = await loginWithUser(data.username, data.password);
        
        if (response.error) {
            setError(true)
        }
    };

    const onSubmit: SubmitHandler<LoginFormInputs> = data => {
        handleLogIn(data)
    };

    return (
        <Box 
            display={"flex"} flexDirection={"column"} alignItems={"center"} justifyContent={"start"}
            width={"100%"} maxWidth={"calc(100% - 20px)"} marginX={"auto"} height={"90dvh"}           
        >
            <BackgroundImage imageUrl="/images/wallpaper2.jpg" />
            <Box width={"100%"} display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"} mt={"2em"}>
                <Box width={"100%"} display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"} my={"1.5em"}>
                    <Box height={isMobile ? "4rem" : "6rem"} width={isMobile ? "4rem" : "6rem"}>
                        <img height={"100%"} width={"100%"} src="/images/Logo.png"/>
                    </Box>
                </Box>
            
                <Typography margin={"0.2em 0 0 0"} variant="h1" textAlign={"center"} fontSize={isMobile ? '2rem' : "4rem"}
                    fontFamily={theme.palette.homepage.fontFamily} color={theme.palette.textColor.title2}>
                    Anmelden
                </Typography>
                <Typography variant="h6" fontFamily={theme.palette.homepage.fontFamily} textAlign={"center"}
                    color={theme.palette.textColor.title2} fontSize={isMobile ? '0.9rem' : "1.25rem"} marginX={isMobile ? "10px" : 0}>
                    Bitte melden Sie sich an
                </Typography>
            </Box>
            <Box 
                component="form"
                onSubmit={handleSubmit(onSubmit)}
                display={"flex"} flexDirection={"column"} alignItems={"center"} justifyContent={"center"}
                maxWidth={"25em"} padding={isMobile ? "25px" : "50px"} textAlign={"center"} borderRadius={"10px"} 
                sx={{backgroundColor: "#ffffff"}} mt={"2em"}
            >
                <TextField
                    label="E-mail/Username"
                    {...register('username', { required: 'E-mail erforderlich' })}
                    error={!!errors.username}
                    helperText={errors.username ? errors.username.message : ' '}
                    variant="outlined"
                    fullWidth
                    sx={{mb: 1.2}}
                    slotProps={{
                        input: {
                            style: { backgroundColor: 'white' },
                        },
                    }}
                />
                <TextField
                    sx={{mb: 1.2}}
                    slotProps={{
                        input: {
                            style: { backgroundColor: 'white' },
                        },
                    }}
                    label="Password"
                    type="password"
                    {...register('password', { required: 'Passwort erforderlich' })}
                    error={!!errors.password}
                    helperText={errors.password ? errors.password.message : ' '}
                    variant="outlined"
                    fullWidth
                />
                <Collapse in={error}>
                    <Alert severity="warning" sx={{ mb: 2 }}>Falsche E-Mail oder Passwort</Alert>
                </Collapse>
                <Button type="submit" variant="contained" color="primary">
                    Anmelden
                </Button>
            </Box>
        </Box>
    );
};


