import React, { useState } from 'react';
import { useAuth } from '../../context/AuthProvider';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, TextField, Collapse, Alert, Button, useMediaQuery, useTheme} from '@mui/material';
import BackgroundImage from '../../components/BackgroundImage';

export const RegisterUser = () => {
    const { register } = useAuth();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const navigate = useNavigate()
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errorPassword, setErrorPassword] = useState(false);
    const [errorUsername, setErrorUsername] = useState(false);
    const [error, setError] = useState(false);

    const handleSubmit = async (e: any) => {
        setError(false)
        if (!username.trim()){
            setErrorUsername(true)
            return
        }
        if (!password || password.length < 8){
            setErrorPassword(true)
            return
        }

        const response = await register(username, password);
        if (response.success){
            navigate("/signin")
            return
        }
        setError(true)
    };

    return (
        <>
        <BackgroundImage imageUrl="/images/wallpaper2.jpg" />
        <Box 
            display={"flex"} flexDirection={"column"} alignItems={"center"} justifyContent={"start"}
            width={"100%"} maxWidth={"calc(100% - 20px)"} marginX={"auto"} height={"90dvh"}           
        >
            <Box width={"100%"} display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"} mt={"2em"}>
                <Box width={"100%"} display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"} my={"1.5em"}>
                    <Box height={isMobile ? "4rem" : "6rem"} width={isMobile ? "4rem" : "6rem"}>
                        <img height={"100%"} width={"100%"} src="/images/Logo.png"/>
                    </Box>
                </Box>
            
                <Typography margin={"0.2em 0 0 0"} variant="h1" textAlign={"center"} fontSize={isMobile ? '2rem' : "4rem"}
                    fontFamily={theme.palette.homepage.fontFamily} color={theme.palette.textColor.title2}>
                    Ein Konto erstellen!
                </Typography>
                <Typography variant="h6" fontFamily={theme.palette.homepage.fontFamily} textAlign={"center"}
                    color={theme.palette.textColor.title2} fontSize={isMobile ? '0.9rem' : "1.25rem"} marginX={isMobile ? "10px" : 0}>
                    Um ein Konto zu erstellen, geben Sie eine gültige E-Mail-Adresse und ein sicheres Passwort ein
                </Typography>
            </Box>
            <Box 
                display={"flex"} flexDirection={"column"} alignItems={"center"} justifyContent={"center"}
                maxWidth={"25em"} padding={isMobile ? "25px" : "50px"} textAlign={"center"} borderRadius={"10px"} 
                sx={{backgroundColor: "#ffffff"}} mt={"2em"}
            >
                <TextField
                    label="E-mail"
                    required
                    error={errorUsername}
                    helperText={errorUsername ? "Geben Sie eine gültige E-Mail-Adresse ein" : ' '}
                    variant="outlined"
                    fullWidth
                    onChange={(e) => setUsername(e.target.value)}
                    sx={{mb: 1.2}}
                    slotProps={{
                        input: {
                            style: { backgroundColor: 'white' },
                        },
                    }}
                />
                <TextField
                    sx={{mb: 1.2}}
                    slotProps={{
                        input: {
                            style: { backgroundColor: 'white' },
                        },
                    }}
                    label="Password"
                    type="password"
                    required
                    error={errorPassword}
                    helperText={errorPassword ? "Bitte wählen Sie ein gültiges Passwort. Ab 8 Zeichen" : ' '}
                    variant="outlined"
                    fullWidth
                    onChange={(e) => setPassword(e.target.value)}
                />
                <Collapse in={error}>
                    <Alert severity="warning" sx={{ mb: 2 }}>Falsche E-Mail oder Passwort</Alert>
                </Collapse>
                <Button onClick={handleSubmit} variant="contained" color="primary">
                    Registrieren
                </Button>
            </Box>
        </Box>
        </>
    );
};
