import { Box, Tooltip, IconButton, Avatar, Menu, MenuItem, Typography, useTheme, Button } from "@mui/material"
import { useState } from "react";
import { useAuth } from "../../../context/AuthProvider";
import LogoutIcon from '@mui/icons-material/Logout';

type Items = "Logout" | string | null

interface IProps {
    groupName: string
}

export const UserMenu: React.FC<IProps> = ({groupName})=>{
    const theme = useTheme();
    const { logout } = useAuth()
    const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const items: Items[] = ['Logout'];

    const handleClickItem = (item: Items)=>{
        if (item === 'Logout'){
            logout()
        }         
    }

    return (
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start", width: "20%" }}>
            <Tooltip title="Options">
                <Button 
                    onClick={handleOpenUserMenu} 
                    sx={{ p: 0, textTransform: "none", display: "inline-flex", gap: 1, justifyContent: "flex-start" }}
                >
                    <Avatar alt="" sx={{ bgcolor: theme.palette.primary.main, width: "28px", height: "28px"}} src="" />
                    <Typography variant="body2" sx={{paddingRight: "10px", overflow: "hidden"}}  textOverflow={"ellipsis"} color={theme.palette.textColor.title}>
                        Willkommen, {groupName}
                    </Typography>
                </Button>
            </Tooltip>
            <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
            >
                {items.map((i) => (
                    <MenuItem key={i} onClick={()=>handleClickItem(i)}>
                        <LogoutIcon /><Typography sx={{ textAlign: 'center' }}>{i}</Typography>
                    </MenuItem>
                ))}
            </Menu>
        </Box>
    )
}