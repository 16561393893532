import { Box, Typography, List, ListItem, ListItemText, TextField, IconButton, Divider, ListItemButton } from "@mui/material";
import { ContributionList } from "../../../utils/Wedding"
import { useState } from "react";
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

interface IProps {
    list: ContributionList;
    add: (value: string, listId: number)=>void;
    remove: (itemId: number, listId: number)=>void;
    groupId?: number | null;
}

export const SingleList: React.FC<IProps> = ({list, add, remove, groupId})=>{
    const [itemName, setItemName] = useState<string>('')
    const [itemError, setItemError] = useState<boolean>(false)

    const handleAdd = ()=>{
        if (itemName.trim().length < 1){
            setItemError(true)
            return
        }
        if (!list.id) return
        add(itemName, list.id)
        setItemName("")
        setItemError(false)
    }

    const handleRemove = (itemId?: number, listId?: number)=>{
        itemId && listId && remove(itemId, listId)
    }

    return (
        <Box display={"flex"} flexDirection={"column"} maxWidth={"350px"}>
            <Typography variant="h6" sx={{mb: 1.5}}>{list.name}</Typography>
            {list.description && <Typography variant="body2" sx={{mb: 1.5}}>{list.description}</Typography>}
            <Box display={"inline-flex"} gap={"5px"}>
                <TextField
                    label={"Ich bringe mit..."}
                    name="item"
                    value={itemName}
                    onChange={(e)=>{
                        itemError && setItemError(false)
                        setItemName(e.target.value)
                    }}
                    error={itemError}
                    size="small"
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            handleAdd();
                        }
                    }}
                />
                <IconButton onClick={handleAdd} color="primary">
                    <AddIcon />
                </IconButton>
            </Box>
            <Divider sx={{my: 2, width: "100%"}}/>
            <List dense disablePadding>
                {list.items.length < 1 && <Typography variant="caption">Niemand hat dieser Liste etwas hinzugefügt</Typography>}
                {list.items.map((item, index)=>{
                    return (
                        <ListItem key={index}
                            secondaryAction={
                                (item.creator === groupId) && <IconButton onClick={()=>handleRemove(item.id, list.id)}>
                                    <DeleteIcon />
                                </IconButton>
                            }
                        >
                            <ListItemButton>
                                <ListItemText>
                                    {item.name}
                                </ListItemText>
                            </ListItemButton>
                        </ListItem>
                    )
                })}
            </List>
            
        </Box>
    )
}