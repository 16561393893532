import React, { useCallback, useState } from "react";
import {
    TextField,
    Button,
    Typography,
    Box,
    Grid2,
    useMediaQuery,
    useTheme,
    Alert,
    Collapse,
} from "@mui/material";
import { ResponsiblePaper } from "../wedding/components/ResponsiblePaper";
import { Wedding } from "../../utils/Wedding";
import BackgroundImage from "../../components/BackgroundImage";
import { useNavigate } from "react-router-dom";
import { BLOCKED_DISPLAY_URLS, DISPLAY_URL_REGEX } from "../../utils/NotPermittedUrl";

export const CreateWedding: React.FC = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const navigate = useNavigate()
    const [invalidData, setInvalidData] = useState(false)
    const [errors, setErrors] = useState({
        name: false,
        displayUrl: false,
    });
    
    const [wedding, setWedding] = useState<Wedding>({
        name: "",
        displayUrl: "",
        description: "",
        backgroundPicture: null,
        infos: "",
        weddingAddress: "",
        weddingDate: null,
        groups: [],
        ageOptions: [],
        foodOptions: [],
        questions: [],
        giftOptions: [],
        lists: [],
        confirmBeforeDate: null,
        giftsDescription: '',
        arrival: "",
        overnight: "",
        dressCode: "",
        performances: ""
    });

    const addWedding = useCallback(async()=>{
        setInvalidData(false)
        fetch("/api/weddings", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(wedding)
        }).then((response)=>{
            if (response.ok){
                return response.json()
            }
            setInvalidData(true)
        }).then((dataResponse: Wedding)=>{
            navigate(`/weddings/${dataResponse.displayUrl}`)
        }).catch((err)=>{
            setInvalidData(true)
        })
    }, [wedding])

    const validateFields = (): boolean => {
        let valid = true
        const newErrors: any = {
            name: "",
            displayUrl: "",
        };
    
        if (!wedding.name.trim()) {
            newErrors.name = "Please enter a name";
            valid = false;
        }
    
        if (!wedding.displayUrl.trim()) {
            newErrors.displayUrl = "Please enter a display url";
            valid = false;
        } else {
            const trimmedUrl = wedding.displayUrl.trim().toLowerCase();
    
            if (!DISPLAY_URL_REGEX.test(trimmedUrl)) {
                newErrors.displayUrl = "The display URL must contain only lowercase letters, numbers, and hyphens, and be 3 to 30 characters long.";
                valid = false;
            }
    
            if (BLOCKED_DISPLAY_URLS.includes(trimmedUrl)) {
                newErrors.displayUrl = "The provided display URL is blocked.";
                valid = false;
            }
        }    
        return valid
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setWedding((prev) => ({ ...prev, [name]: value }));
        setErrors(prev => ({...prev, [name]: false}))
    };

    const handleSubmit = () => {
        if (!validateFields()) return
        addWedding()
    };

    return (
        <Box>
            <Box width={"100%"} display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"} mt={"2em"}>
                <BackgroundImage imageUrl="/images/wallpaper2.jpg"/>
                <Box width={"100%"} display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"} my={"1.5em"}>
                    <Box height={isMobile ? "4rem" : "6rem"} width={isMobile ? "4rem" : "6rem"}>
                        <img height={"100%"} width={"100%"} src="/images/Logo.png"/>
                    </Box>
                </Box>
            
                <Typography margin={"0.2em 0 0 0"} variant="h1" textAlign={"center"} fontSize={isMobile ? '2rem' : "4rem"}
                    fontFamily={theme.palette.homepage.fontFamily} color={theme.palette.textColor.title2}>
                    Eine Hochzeitswebsite erstellen
                </Typography>
                <Typography variant="h6" fontFamily={theme.palette.homepage.fontFamily} textAlign={"center"}
                    color={theme.palette.textColor.title2} fontSize={isMobile ? '0.8rem' : "1.25rem"} marginX={isMobile ? "10px" : 0}>
                    Willkommen, schreiben Sie zunächst Ihre Namen und den Link zu Ihrer Website
                </Typography>
            </Box>

            <Box sx={{ padding: isMobile ? 0 : 4, mt: "2em" }}>
                <ResponsiblePaper autoMargin fullWidth widthReduction={isMobile ? '1em' : ''} elevation={3} style={{ padding: isMobile ? '10px' : '15px', maxWidth: '500px'  }}>
                    <Grid2 container spacing={3}>
                        <Grid2 size={12}>
                            <TextField
                                fullWidth
                                label="Name"
                                name="name"
                                value={wedding.name}
                                onChange={handleInputChange}
                                error={errors.name}
                                required
                            />
                        </Grid2>
                        <Grid2 size={12}>
                            <TextField
                                fullWidth
                                label="Display URL"
                                name="displayUrl"
                                value={wedding.displayUrl}
                                error={errors.displayUrl}
                                onChange={handleInputChange}
                                helperText={errors.displayUrl || ' '}
                            />
                        </Grid2>
                        <Grid2 size={12}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSubmit}
                            >
                                Save Wedding
                            </Button>
                        </Grid2>
                    </Grid2>
                    <Collapse in={invalidData}>
                        <Alert severity="warning" sx={{ mt: 2 }}>Unbekanntes Fehler</Alert>
                    </Collapse>
                </ResponsiblePaper>
            </Box>
        </Box>
    );
};

