import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

interface IProps {
    value: string
    onChange: (value: string)=>void
}

export const HtmlEditor: React.FC<IProps> = ({value, onChange})=>{

    return (
        <ReactQuill
            theme="snow"
            value={value}
            onChange={onChange}
            style={{maxWidth: "700px"}}
            modules={{
                toolbar: [
                    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                    [{ 'size': [] }], // Fontes e Tamanhos
                    ['bold', 'italic', 'underline', 'strike'], // Formatação básica
                    [{ 'color': [] }, { 'background': [] }], // Cor de texto e fundo
                    [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'indent': '-1'}, { 'indent': '+1' }], // Listas e recuo
                    [{ 'direction': 'rtl' }, { 'align': [] }], // Direção e alinhamento
                    ['link', 'image', 'video'], // Inserir links e mídias
                    ['clean'] // Remover formatação
                ],
            }}
            formats={
                [
                    'header',
                    'size',
                    'bold', 'italic', 'underline', 'strike',
                    'color', 'background',
                    'list', 'bullet', 'indent',
                    'direction', 'align',
                    'link', 'image', 'video'
                ]
            }
        />
    )
}