import React from 'react'
import BackgroundImage from './BackgroundImage'
import { Box, Button, Typography } from '@mui/material'
import ErrorIcon from '@mui/icons-material/Error';

interface IProps {
    weddingId?: string
}

export const AdminNotFoundPage: React.FC<IProps> = ({weddingId})=>{
    
    return (
        <Box display={"inline-flex"} alignItems={"center"} justifyContent={"center"} margin={"auto"} height={"100dvh"} width={"100%"}>
            <BackgroundImage imageUrl='/images/wallpaper2.jpg'/>
            <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"} gap={2}>
                <ErrorIcon sx={{height: "4em", width: "4em"}}/>
                <Typography paddingX={"1em"} sx={{maxWidth: "500px"}}  textAlign={"center"} variant='h5'>
                    {weddingId ? <>Hochzeitswebsite "{weddingId}" nicht gefunden</> : <>Hochzeitswebsite nicht gefunden</>}
                </Typography>
                <Typography variant='subtitle1' paddingX={"1em"} sx={{maxWidth: "500px"}} textAlign={"center"}>
                    Bitte stellen Sie sicher, dass die Adresse korrekt ist, und versuchen Sie, die Seite zu aktualisieren
                </Typography>
                <Button variant="contained" color="primary" onClick={()=>{
                    window.location.reload()
                }}>Aktualisieren</Button>
            </Box>
        </Box>
    )
}