import React, {  useEffect, useState } from "react"
import { useWeddingAdmin } from "../../context/WeddingAdminProvider"
import { List, ListItem, IconButton, ListItemText, ToggleButtonGroup, ToggleButton, Box, Grid2, TextField, Button, Typography,  ListItemButton, useMediaQuery, useTheme, ListItemIcon, Divider } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import { GiftOption, Wedding } from "../../utils/Wedding";
import { ResponsiblePaper } from "../wedding/components/ResponsiblePaper";
import Filter1Icon from '@mui/icons-material/Filter1';
import FilterNoneIcon from '@mui/icons-material/FilterNone';
import { HtmlEditor } from "./components/HtmlEditor";
import { ConfirmCancelModal } from "./components/ConfirmCancelModal";


export const Gifts: React.FC<any> = ()=>{
    const { wedding, weddingId, setWedding, refetch, alertSnack } = useWeddingAdmin();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const [gift, setGift] = useState<GiftOption>({
        exclusive: true,
        name: ''
    })
    const [error, setError] = useState<boolean>(false)
    const [description, setDescription] = useState<string>(wedding?.giftsDescription || "")
    const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false)
    const [modalRemove, setModalRemove] = useState<boolean>(false)
    const [selectedToRemove, setSelectedToRemove] = useState<number>(0)

    useEffect(()=>{
        setDescription(wedding?.giftsDescription || "")
    }, [wedding?.giftsDescription])    

    const handleChangeGiftName = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>)=>{
        error && setError(false)
        setGift(prev => ({...prev, name: e.target.value }))
    }

    const handleChangeGiftExclusive = ()=>{
        setGift(prev => ({...prev, exclusive: !prev.exclusive }))
    }

    const handleAddGift = async()=>{
        if (!gift.name) {
            setError(true)
            return
        }
        // const newGift = {name: gift.name, isExclusive: gift.exclusive}
        fetch(`/api/weddings/${weddingId}/gifts`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(gift)
        }).then((response)=>{
            if (response.ok) return response.json()
            return
        }).then((dataResponse)=>{
            if (dataResponse?.giftId){
                // Push GiftOption to Wedding
                const newGift: GiftOption = {...gift, id: dataResponse.giftId}
                const updatedWedding: Wedding | null = wedding
                ? {
                    ...wedding,
                    giftOptions: [...(wedding.giftOptions || []), newGift],
                } : null;
                setWedding(updatedWedding);
                alertSnack({text: "Added", type: "success"})
                return
            }
            // Error, refetch
            refetch()
        }).catch((err)=>{
            alertSnack({text: "Error", type: "error"})
        }).finally(()=>{
            setGift({
                exclusive: true,
                name: ''
            })
        })
    }

    const handleToggleExclusive = async(giftId?: number)=>{
        if (!giftId) return
        fetch(`/api/weddings/${weddingId}/gifts/${giftId}/exclusive/toggle`, {
                method: "PUT",
                headers: {
                    'Content-Type': 'application/json'
                }
        }).then((response)=>{
            if (response.ok){
                const oldGift: GiftOption | undefined = wedding?.giftOptions.find(g => g.id === giftId)

                if (!oldGift){
                    refetch()
                    return
                }

                const updatedGiftOptions: GiftOption[] = wedding?.giftOptions.map(gift =>
                    gift.id === giftId ? { ...gift, exclusive: !gift.exclusive } : gift
                ) || [];
            
                const updatedWedding: Wedding | null = wedding ? {...wedding, giftOptions: updatedGiftOptions, } : null;
                setWedding(updatedWedding);
                alertSnack({text: "Toggled", type: "success"})
                return
            }
            // Error, refetch
            refetch()
        }).catch((err)=>{
            alertSnack({text: "Error", type: "error"})
        }).finally(()=>{
            
        })
    }
    
    const handleRemoveGift = async(giftId?: number)=>{
        if (!giftId) return
        fetch(`/api/weddings/${weddingId}/gifts/${giftId}`, {
                method: "DELETE",
                headers: {
                    'Content-Type': 'application/json'
                }
        }).then((response)=>{
            if (response.ok) {
                const oldGift = wedding?.giftOptions.find(g => g.id === giftId);
                if (!oldGift) {
                    refetch();
                    return;
                }
    
                const newGiftOptions = wedding?.giftOptions.filter(go => go.id !== giftId) || [];
                const updatedWedding: Wedding | null = wedding
                    ? {
                          ...wedding,
                          giftOptions: newGiftOptions,
                      }
                    : null;
    
                setWedding(updatedWedding);
                alertSnack({text: "Removed", type: "success"})
            } else {
                refetch();
            }
        }).catch((err)=>{
            alertSnack({text: "Error", type: "error"})
        })
    }

    const handleSaveDescription = async()=>{
        const payload = { description }
        fetch(`/api/weddings/${weddingId}/gifts/description`, {
            method: "PUT",
            credentials: "include",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        }).then((response)=>{
            if (response.ok) {
                setUnsavedChanges(false)
                alertSnack({text: "Added", type: "success"})
                return
            } else {
                alertSnack({text: "Error", type: "error"})
            }

        }).catch((err)=>{
            alertSnack({text: "Error", type: "error"})
        })
    }

    return (
        <>
            <ResponsiblePaper fullWidth widthReduction={isMobile ? '1em' : '2em'} elevation={3} style={{marginTop: "2em", ml: isMobile ? "0.5em" : "1em"}} >
                <Grid2 size={12} width={'100%'} maxWidth={1000} minWidth={250}>
                    <Typography variant="h6" sx={{mb: 1}}>Gifts description</Typography>
                    <HtmlEditor value={description} 
                        onChange={(value) => {
                            !unsavedChanges && setUnsavedChanges(true)
                            setDescription(value)
                        }}
                    />
                    <Button sx={{mt: 2}} onClick={handleSaveDescription} color="primary" variant="contained">Save</Button>
                </Grid2>
            </ResponsiblePaper>
            <ResponsiblePaper fullWidth widthReduction={isMobile ? '1em' : '2em'} elevation={3} style={{marginTop: "2em", ml: isMobile ? "0.5em" : "1em"}}>
                <Grid2 container spacing={3} maxWidth={"1000px"}>
                    <Box display={"inline-flex"} gap={2} flexWrap={"wrap"}>
                        <TextField
                            label="Gift Name"
                            name="giftname"
                            value={gift.name}
                            onChange={handleChangeGiftName}
                            error={error}
                            required
                            size="small"
                        />
                        <ToggleButtonGroup exclusive
                            value={!!gift.exclusive}   
                            onChange={handleChangeGiftExclusive}     
                            size="small"            
                        >
                            <ToggleButton size="small" value={true} sx={{display: "inline-flex", gap: 1}}><Filter1Icon />{!isMobile && <>Exlusive</>}</ToggleButton>
                            <ToggleButton size="small" value={false} sx={{display: "inline-flex", gap: 1}}><FilterNoneIcon />{!isMobile && <>Multiple</>}</ToggleButton>
                        </ToggleButtonGroup>
                        <Button variant="contained"
                                color="primary"
                                onClick={handleAddGift}>
                            Add
                        </Button>
                    </Box>
                </Grid2>
                <Divider sx={{my: 2, width: "100%"}}/>
                <List disablePadding dense sx={{bgcolor: 'background.paper', minWidth: "250px", maxWidth: "900px", width: "100%"}}>
                    {wedding?.giftOptions?.map((g, i)=>{
                        return (
                            <ListItem key={i} disablePadding sx={{width: "100%", mt: 1}}
                                secondaryAction={
                                    <IconButton onClick={()=>{
                                        setSelectedToRemove(g.id!)
                                        setModalRemove(true)
                                    }} edge="end" aria-label="delete">
                                        <DeleteIcon />
                                    </IconButton>
                                }
                            >
                                <ListItemButton sx={{width: "100%"}}>
                                    <ListItemText sx={{width: "100%"}} primary={
                                        <Typography fontWeight={"500"} variant="body2" sx={{whiteSpace: "wrap", textOverflow: "ellipsis", overflow: "hidden"}} >
                                            {g.name}
                                        </Typography>} 
                                    />
                                    <ListItemIcon>
                                        <ToggleButtonGroup size="small"  exclusive value={!!g.exclusive} onChange={()=>handleToggleExclusive(g.id)}>
                                            <ToggleButton size="small" value={true} sx={{display: "inline-flex", gap: 1}}><Filter1Icon />{!isMobile && <>Exlusive</>}</ToggleButton>
                                            <ToggleButton size="small" value={false} sx={{display: "inline-flex", gap: 1}}><FilterNoneIcon />{!isMobile && <>Multiple</>}</ToggleButton>
                                        </ToggleButtonGroup>
                                    </ListItemIcon>
                                </ListItemButton>
                            </ListItem>
                        )
                    })}
                </List>
            </ResponsiblePaper>
            <ConfirmCancelModal 
                open={modalRemove} 
                onClose={()=>setModalRemove(false)} 
                onConfirm={()=>{
                    setModalRemove(false)
                    handleRemoveGift(selectedToRemove)
                }}
                title="Remove this gift?"
                subtitle="This action is irreversible"
            />
        </>
    )
}