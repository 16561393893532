import React from 'react'
import { Box } from '@mui/material'
import BackgroundImage from '../../components/BackgroundImage';

interface IProps {
}

export const HelpPage: React.FC<IProps> = ({})=>{
    
    return (
        <Box display={"inline-flex"} alignItems={"center"} justifyContent={"center"} margin={"auto"} height={"100dvh"} width={"100%"}>
            <BackgroundImage imageUrl='/images/wallpaper2.jpg'/>
            <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"} gap={2}>
                Im Aufbau
                
            </Box>
        </Box>
    )
}