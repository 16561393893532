import { Dialog, Button, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material"

interface IProps {
    open: boolean;
    onClose: ()=>void
    onConfirm: ()=>void;
    title: string;
    subtitle: string;
    textConfirmButton?: string;
    textCancelButton?: string;

}

export const ConfirmCancelModal: React.FC<IProps> = ({open, onClose, onConfirm, title, subtitle, textConfirmButton, textCancelButton})=>{

    return (
        <Dialog
            open={open}
            onClose={onClose}
        >
            <DialogTitle>
                {title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {subtitle}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>{textCancelButton || "Cancel"}</Button>
                <Button variant="contained" color="error" onClick={onConfirm} autoFocus>
                    {textConfirmButton || "Yes, delete!"}
                </Button>
            </DialogActions>
        </Dialog>
    )
}