import BackgroundImage from "../../components/BackgroundImage";
import { Box, Button, Collapse, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
// import Instruction1 from "./Instructions1";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

type WeddingSearchResult = {
    name: string
    displayUrl: string
}

export const Homepage = ()=>{
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const [searchValue, setSearchValue] = useState<string>('')
    const [results, setResults] = useState<WeddingSearchResult[]>([])
    const [notFound, setNotFound] = useState<boolean>(false)
    const navigate = useNavigate()

    const handleSearch = ()=>{
        setNotFound(false)
        fetch(`/api/weddings/search?name=${searchValue}`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((response)=>{
            if(response.ok) return response.json()
        }).then((data)=>{
            if (data.length < 1) setNotFound(true)
            setResults(data)
        }).catch((err)=>{

        })
    }

    return (
        <Box position={"relative"} paddingBottom={"50px"} height={"100%"} width={"100%"}>
            <Button onClick={()=>navigate("/weddings")} variant="contained" sx={{position: "absolute", bottom: "10px", right: "10px"}}>Hochzeits-Login</Button>
            <BackgroundImage imageUrl="/images/wallpaper2.jpg" />
            <Box width={"100%"} display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"} mt={"2em"}>
                <Box height={isMobile ? "6rem" : "8rem"} width={isMobile ? "6rem" : "8rem"}>
                    <img height={"100%"} width={"100%"} src="/images/Logo.png"/>
                </Box>
                <Typography margin={"0.2em 0 0 0"} variant="h1" textAlign={"center"} fontSize={isMobile ? '3rem' : "6rem"}
                    fontFamily={theme.palette.homepage.fontFamily} color={theme.palette.textColor.title2}>
                    Liebe im Blick
                </Typography>
                <Typography variant="h6" fontFamily={theme.palette.homepage.fontFamily} 
                    color={theme.palette.textColor.title2} textAlign={"center"} fontSize={isMobile ? '1rem' : "1.25rem"}>
                    Ihr Hochzeitsplaner
                </Typography>
                <Typography variant="h6" fontFamily={theme.palette.homepage.fontFamily} 
                    color={theme.palette.textColor.title} textAlign={"center"} fontSize={isMobile ? '1rem' : "1.25rem"} sx={{mt: 3}}>
                    Erstellen Sie kostenlos ein Konto und verwalten Sie damit Ihre Hochzeit und Einladungen!
                </Typography>
                <Typography variant="h6" fontFamily={theme.palette.homepage.fontFamily} 
                    color={theme.palette.textColor.title} textAlign={"center"} fontSize={isMobile ? '1rem' : "1.25rem"}>
                    Die Website befindet sich im Aufbau, ist aber bereits zu 100% funktionsfähig
                </Typography>
                <Button onClick={()=>navigate("/signup")} variant="contained" sx={{mt: 1.5}}>
                    Konto erstellen
                </Button>

            </Box>
            <Box
                sx={{
                    textAlign: "center",
                    margin: isMobile ? "50px 1em" : "50px auto",
                    padding: "20px",
                    maxWidth: "500px" ,
                    background: "#ffffff",
                    borderRadius: "8px",
                }}
            >
                <Typography variant="h6" sx={{ marginBottom: "20px" }} fontSize={"0.7rem"}>
                    Suchen Sie die Hochzeitsseite? Wenn Sie keinen Zugriff darauf haben, versuchen Sie es mit der Suche oder kontaktieren Sie das Brautpaar
                </Typography>
                <Box 
                    sx={{ 
                        display: "flex", 
                        justifyContent: "space-around", 
                        alignItems: "center", 
                        marginBottom: "20px" 
                    }}
                >
                    {/* <Instruction1/> */}
                </Box>
                <TextField 
                    label="Name des Hochzeitspaares" 
                    variant="outlined" 
                    fullWidth 
                    sx={{ marginBottom: "20px" }}
                    value={searchValue}
                    onChange={(e)=>setSearchValue(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            handleSearch();
                        }
                    }}
                />
                <Button
                    onClick={handleSearch} 
                    variant="contained" 
                    color="primary" 
                    sx={{ width: "100%" }}
                >
                    Hochzeit finden
                </Button>
                <Collapse in={results.length > 0}>
                    <Box display={"flex"} flexDirection={"column"} gap={1}>
                        <Typography mt={"1em"} variant="caption" color="textSecondary">Ergebnisse gefunden:</Typography>
                        {results.map((result, index)=>{
                            return (
                                <Button key={index} onClick={()=>navigate(`/${result.displayUrl}`)}>{result.name}</Button>
                            )
                        })}
                    </Box>
                </Collapse>
                <Collapse in={results.length < 1 && notFound}>
                    <Typography mt={"1em"} variant="caption" color="textSecondary">Nichts gefunden</Typography>
                </Collapse>
            </Box>
        </Box>

    );
}