import { Box, IconButton, Typography, AvatarGroup, Avatar, useMediaQuery, useTheme, TextField } from "@mui/material";
import React from "react";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import LoopIcon from '@mui/icons-material/Loop';
import { Group } from "../../../utils/Wedding";

interface IProps {
    onGuestAdd: ()=>void;
    onGuestRemove: ()=>void;
    onNameChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>)=>void;
    group: Group;
    children: React.ReactNode;
    onRegenerateQRCode: ()=>void;
}

export const AddInvitation: React.FC<IProps> = ({onGuestAdd, onGuestRemove, onNameChange, group, children, onRegenerateQRCode})=>{
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <Box display={"flex"} flexDirection={"column"} sx={{ minWidth: 275, maxWidth: "30rem", mt: 2.5 }}>
                <Box display={"inline-flex"} justifyContent={'space-between'} width={"100%"} gap={2}>
                    <TextField
                        fullWidth
                        label="Invitation name"
                        name="invitationName"
                        value={group.name}
                        onChange={onNameChange}
                        placeholder="Mr. and Mrs. Mustermann"
                    />
                    <TextField
                        disabled
                        label="Invitation code"
                        name="invitationCode"
                        value={group.entryCode}
                    />
                    <Box display={"inline-flex"} alignItems={"center"}>
                        <IconButton onClick={onRegenerateQRCode}>
                            <LoopIcon />
                        </IconButton>
                    </Box>
                </Box>
                
                <Box display={'flex'} flexDirection={"column"} justifyContent={'space-between'} sx={{width: '100%', mt: 1.5, mb: 1.5}}>
                    <Box display={'inline-flex'} alignItems={'center'}>
                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>Number of guests:</Typography>
                        <IconButton onClick={onGuestAdd}>
                            <AddIcon />
                        </IconButton>
                        <IconButton onClick={onGuestRemove}>
                            <RemoveIcon />
                        </IconButton>
                    </Box>
                    <AvatarGroup max={5} sx={{alignSelf: "flex-start", "& .MuiAvatarGroup-avatar": { width: 24, height: 24, fontSize: 14 }}}>
                        {group.guests?.map((_, index)=>{
                            return <Avatar key={index} sx={{ width: 24, height: 24 }} alt="" src=""/>
                        })}
                    </AvatarGroup>
                </Box>
            {children}
        </Box>
    )
}