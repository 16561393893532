import React from 'react'
import BackgroundImage from './BackgroundImage'
import { Box, Button, Typography } from '@mui/material'
import ErrorIcon from '@mui/icons-material/Error';
import { useAuth } from '../context/AuthProvider';

interface IProps {
    error: string
}

export const AdminErrorPage: React.FC<IProps> = ({error})=>{
    const { logout } = useAuth()
    return (
        <Box display={"inline-flex"} alignItems={"center"} justifyContent={"center"} margin={"auto"} height={"100dvh"} width={"100%"}>
            <BackgroundImage imageUrl='/images/wallpaper2.jpg'/>
            <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"} gap={2}>
                <ErrorIcon sx={{height: "4em", width: "4em"}}/>
                <Typography paddingX={"1em"} sx={{maxWidth: "500px"}} textAlign={"center"} variant='h5'>Fehler, bitte versuchen Sie, die Seite zu aktualisieren oder sich abzumelden und erneut anzumelden</Typography>
                <Typography paddingX={"1em"} sx={{maxWidth: "500px"}} textAlign={"center"} variant='subtitle1'>{error}</Typography>
                <Button variant="contained" color="primary" onClick={()=>{
                    logout()
                    window.location.reload()
                }}>Aktualisieren</Button>
            </Box>
        </Box>
    )
}