import React, { useEffect, useState } from "react"
import { useWeddingAdmin } from "../../context/WeddingAdminProvider"
import { Box, Button, Grid2, IconButton, List, ListItem, ListItemButton, ListItemText, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import { ResponsiblePaper } from "../wedding/components/ResponsiblePaper";
import ErrorsMessage from "../../utils/ErrorsMessage";
import { ContributionList, Wedding } from "../../utils/Wedding";
import DeleteIcon from '@mui/icons-material/Delete';
import { ConfirmCancelModal } from "./components/ConfirmCancelModal";


export const Lists: React.FC<any> = ()=>{
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const { wedding, weddingId, alertSnack, setWedding, refetch } = useWeddingAdmin();
    const [name, setName] = useState<string>("")
    const [description, setDescription] = useState<string>("")
    const [errorName, setErrorName] = useState<boolean>(false)
    const [modalRemove, setModalRemove] = useState<boolean>(false)
    const [selectedToRemove, setSelectedToRemove] = useState<number>(0)


    const handleAdd = ()=>{
        if (!name.trim()){
            setErrorName(true)
            return
        }
        const list: ContributionList = {
            name,
            description,
            items: []
        }

        fetch(`/api/weddings/${weddingId}/lists`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(list)
        }).then((response)=>{
            if (response.ok) {
                return response.json()
            }
            if (ErrorsMessage.hasOwnProperty(response.status)) {
                alertSnack({ text: ErrorsMessage[response.status], type: "warning" });
                return;
            }
            alertSnack({ text: ErrorsMessage["400"], type: "error" });
        }).then((data)=>{
            if (data?.listId){
                // Push List to Wedding, with id
                const newList: ContributionList = { ...list, id: data.listId }
                const updatedLists: ContributionList[] = [...wedding?.lists || [], {...newList}]
                const updatedWedding: Wedding | null = wedding ? { 
                    ...(wedding || {}), 
                    lists: updatedLists 
                } : null;
                setWedding(updatedWedding);
                alertSnack({text: "Success", type: "success"})
                return
            }
            // Error, refetch
            refetch()
        }).catch((err)=>{
            alertSnack({ text: ErrorsMessage["400"], type: "error" });
        }).finally(()=>{
            setName("")
            setDescription("")

        })
    }    

    const handleRemoveList = (listId?: number)=>{
        if (!listId) return
        fetch(`/api/weddings/${weddingId}/lists/${listId}`, {
                method: "DELETE",
                headers: {
                    'Content-Type': 'application/json'
                }
        }).then((response)=>{
            if (response.ok) {
                const oldList = wedding?.lists.find(g => g.id === listId);
                if (!oldList) {
                    refetch();
                    return;
                }
    
                const newLists = wedding?.lists.filter(l => l.id !== listId) || [];
                const updatedWedding: Wedding | null = wedding
                    ? {
                          ...wedding,
                          lists: newLists,
                      }
                    : null;
    
                setWedding(updatedWedding);
                alertSnack({text: "Removed", type: "success"})
            } else {
                refetch();
            }
        }).catch((err)=>{
            alertSnack({text: "Error", type: "error"})
        })
    }

    return <>
        <ResponsiblePaper autoMargin fullWidth widthReduction={isMobile ? '1em' : '2em'} elevation={3} style={{marginTop: "2em"}}> 
            <Grid2 size={12} width={'100%'} maxWidth={1000} minWidth={300}>
                <Typography variant="h6" sx={{mb: 1}}>Contribution Lists</Typography>
                <Box display={"flex"} flexDirection={"column"} gap={2}>
                    <TextField
                        label="List name"
                        name="Name"
                        value={name}
                        onChange={(e)=>setName(e.target.value)}
                        placeholder="Cake list / Wine list [...]"
                        required
                        error={errorName}
                        sx={{maxWidth: "300px"}}
                    />
                    <TextField
                        fullWidth
                        label="Description"
                        name="Description"
                        value={description}
                        onChange={(e)=>setDescription(e.target.value)}
                        placeholder="This is a list that you can..."
                    />
                </Box>
            </Grid2>
            <Grid2 size={12} sx={{mt: 2}}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleAdd}
                >
                    Add
                </Button>
            </Grid2>
        </ResponsiblePaper>
        <ResponsiblePaper autoMargin fullWidth widthReduction={isMobile ? '1em' : '2em'} elevation={3} style={{marginTop: "2em"}}>
            <Typography variant="body1" sx={{mb: 1}}>All lists</Typography>
            {wedding?.lists.length === 0 && <Typography variant="body2" color="textSecondary" sx={{mb: 2}}>You don't have any list...</Typography>}
            <List dense disablePadding>
                {wedding?.lists.map((list, index)=> {
                    return (
                        <ListItem key={index}
                            secondaryAction={
                                <IconButton onClick={()=>{
                                    setSelectedToRemove(list.id!)
                                    setModalRemove(true)
                                }} edge="end" aria-label="delete">
                                    <DeleteIcon />
                                </IconButton>
                            }
                        >
                            <ListItemButton sx={{width: "100%"}}>
                                <ListItemText sx={{width: "100%"}} 
                                    primary={
                                        <Typography color="primary" sx={{whiteSpace: "wrap", textOverflow: "ellipsis", overflow: "hidden"}} >
                                            {list.name}
                                        </Typography>
                                    }
                                    secondary={
                                        <Typography variant="body2" textOverflow={"ellipsis"} fontSize={"0.8rem"} sx={{ overflow: "hidden"}} >
                                            {list.description || ''}
                                        </Typography>
                                    }
                                />
                            </ListItemButton>
                        </ListItem>
                    )
                })}
            </List>
        </ResponsiblePaper>
        <ConfirmCancelModal 
            open={modalRemove} 
            onClose={()=>setModalRemove(false)} 
            onConfirm={()=>{
                setModalRemove(false)
                handleRemoveList(selectedToRemove)
            }}
            title="Delete list?"
            subtitle="This action is irreversible"
        />
    </>
}