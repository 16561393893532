import React, { useEffect, useState } from "react"
import { useWeddingAdmin } from "../../context/WeddingAdminProvider"
import { Button, Grid2, Typography, useMediaQuery, useTheme } from "@mui/material";
import { ResponsiblePaper } from "../wedding/components/ResponsiblePaper";
import ErrorsMessage from "../../utils/ErrorsMessage";
import { HtmlEditor } from "./components/HtmlEditor";


export const Description: React.FC<any> = ()=>{
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const { wedding, weddingId, alertSnack } = useWeddingAdmin();
    const [description, setDescription] = useState<string>(wedding?.description || "")

    useEffect(()=>{
        setDescription(wedding?.description || "")
    },[wedding?.description])

    const handleSave = ()=>{
        const payload = { description }
        fetch(`/api/weddings/${weddingId}/description`, {
                method: "PUT",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
        }).then((response)=>{
            if (response.ok) {
                alertSnack({text: "Success", type: "success"})
                return
            }
            if (ErrorsMessage.hasOwnProperty(response.status)) {
                alertSnack({ text: ErrorsMessage[response.status], type: "warning" });
                return;
            }
        }).catch((err)=>{
            alertSnack({ text: ErrorsMessage["400"], type: "error" });
        })
    }    

    return <>
        <ResponsiblePaper autoMargin fullWidth widthReduction={isMobile ? '1em' : '2em'} elevation={3} style={{marginTop: "2em"}}> 
            <Grid2 size={12} width={'100%'} maxWidth={1000} minWidth={300}>
                <Typography variant="h6" sx={{mb: 1}}>Description</Typography>
                <HtmlEditor value={description} onChange={(value) => setDescription(value)}/>
            </Grid2>
            <Grid2 size={12} sx={{mt: 2}}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSave}
                >
                    Save
                </Button>
            </Grid2>
        </ResponsiblePaper>
    </>
}