import React, { useEffect } from "react";
import BackgroundImage from "../../components/BackgroundImage";
import { Navigation } from "./components/Navigation";
import { useWedding } from "../../context/WeddingProvider";
import { PictureWithName } from "./components/PictureWithName";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { ResponsiblePaper } from "./components/ResponsiblePaper";
import { Guests } from "./components/Guests";
import { GiftList } from "./components/GiftList";
import { FontFamilyTitle } from "../../utils/Wedding";
import "react-quill/dist/quill.snow.css";
import HtmlDiv from "../../components/HtmlQuillDiv";
import { Dayjs } from "dayjs";
import { AllLists } from "./components/AllLists";


export const WeddingHomepage: React.FC = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const { wedding } = useWedding()

    const fontTitle: FontFamilyTitle = `${wedding?.fontFamilyTitle || "Bilbo Swash Caps"}`
    
    useEffect(()=>{
        document.title = wedding?.name || "Liebe im Blick, Ihr Hochzeitsplaner"
    },[wedding])


    return (
        <>
        <div id='home' style={{visibility: 'hidden', position: 'absolute', top: 0}}></div>
        <Navigation />
        <BackgroundImage imageUrl="/images/wallpaper2.jpg" />
        <Box sx={{maxWidth: '80em', margin: 'auto', marginTop: isMobile ? '50px' : '104px'}}>
            <Box display={'flex'} flexDirection={'column'} gap={5} paddingBlockEnd={'2rem'}>
                
                {/* Picture */}
                <PictureWithName url={wedding?.backgroundPicture || ''} title={wedding?.name} fontTitle={fontTitle} date={wedding?.weddingDate as Dayjs}/>
                
                {/* Welcome Description */}
                {wedding?.description && <ResponsiblePaper fullWidth widthReduction={'20px'} centerContent autoMargin
                    style={{backgroundColor: theme.palette.description.background,color: theme.palette.description.color, padding: '2rem 1rem'}}
                >
                    <HtmlDiv data={wedding?.description}/>
                </ResponsiblePaper>}
                
                {/* Timeline / Information */}
                {wedding?.infos && <>
                    <Typography id='infos' variant="h3" fontFamily={`${fontTitle}, cursive`} textAlign={"center"}>Tagesablauf</Typography>
                    <ResponsiblePaper fullWidth widthReduction={'20px'} centerContent autoMargin 
                        style={{padding: '2rem 1rem', backgroundColor: theme.palette.homepage.bgpaper,color: theme.palette.homepage.fontcolorpaper}}>
                        <HtmlDiv data={wedding?.infos}/>
                    </ResponsiblePaper>
                </>}
                
                {/* Arrival */}
                {wedding?.arrival && <>
                    <Typography id='arrival' variant="h3" fontFamily={`${fontTitle}, cursive`} textAlign={"center"}>Anfahrt</Typography>
                    <ResponsiblePaper fullWidth widthReduction={'20px'} centerContent autoMargin 
                        style={{padding: '2rem 1rem', backgroundColor: theme.palette.homepage.bgpaper,color: theme.palette.homepage.fontcolorpaper}}>
                        <HtmlDiv data={wedding?.arrival}/>
                    </ResponsiblePaper>
                </>}

                {/* Overnight */}
                {wedding?.overnight && <>
                    <Typography id='overnight' variant="h3" fontFamily={`${fontTitle}, cursive`} textAlign={"center"}>Übernachtung</Typography>
                    <ResponsiblePaper fullWidth widthReduction={'20px'} centerContent autoMargin 
                        style={{padding: '2rem 1rem', backgroundColor: theme.palette.homepage.bgpaper,color: theme.palette.homepage.fontcolorpaper}}>
                        <HtmlDiv data={wedding?.overnight}/>
                    </ResponsiblePaper>
                </>}

                {/* Dress code */}
                {wedding?.dressCode && <>
                    <Typography id='dresscode' variant="h3" fontFamily={`${fontTitle}, cursive`} textAlign={"center"}>Dresscode</Typography>
                    <ResponsiblePaper fullWidth widthReduction={'20px'} centerContent autoMargin 
                        style={{padding: '2rem 1rem', backgroundColor: theme.palette.homepage.bgpaper,color: theme.palette.homepage.fontcolorpaper}}>
                        <HtmlDiv data={wedding?.dressCode}/>
                    </ResponsiblePaper>
                </>}

                {/* Group actions */}
                <Typography id='group' variant="h3" fontFamily={`${fontTitle}, cursive`} textAlign={"center"}>Angaben</Typography>
                <ResponsiblePaper fullWidth widthReduction={'20px'} centerContent autoMargin 
                    style={{padding: '2rem 1rem', backgroundColor: theme.palette.homepage.bgpaper,color: theme.palette.homepage.fontcolorpaper}}>
                    <Guests />
                </ResponsiblePaper>

                {/* Gift list */}
                {(wedding?.giftOptions.length || wedding?.giftsDescription) && <>
                    <Typography id='gifts' variant="h3" fontFamily={`${fontTitle}, cursive`} textAlign={"center"}>Geschenke</Typography>
                    <ResponsiblePaper fullWidth widthReduction={'20px'} centerContent autoMargin 
                        style={{padding: '2rem 1rem', backgroundColor: theme.palette.homepage.bgpaper,color: theme.palette.homepage.fontcolorpaper}}>
                        <GiftList />
                    </ResponsiblePaper>
                </>}
                
                {/* Other lists */}
                {wedding?.lists.length !== 0 && (<>
                    <Typography id='lists' variant="h3" fontFamily={`${fontTitle}, cursive`} textAlign={"center"}>Listen</Typography>
                    <ResponsiblePaper fullWidth widthReduction={'20px'} centerContent autoMargin 
                        style={{padding: '2rem 1rem', backgroundColor: theme.palette.homepage.bgpaper,color: theme.palette.homepage.fontcolorpaper}}>
                        <AllLists />
                    </ResponsiblePaper>
                </>)}

                {/* Performances */}
                {wedding?.performances && <>
                    <Typography id='performances' variant="h3" fontFamily={`${fontTitle}, cursive`} textAlign={"center"}>Beiträge</Typography>
                    <ResponsiblePaper fullWidth widthReduction={'20px'} centerContent autoMargin 
                        style={{padding: '2rem 1rem', backgroundColor: theme.palette.homepage.bgpaper,color: theme.palette.homepage.fontcolorpaper}}>
                        <HtmlDiv data={wedding?.performances}/>
                    </ResponsiblePaper>
                </>}

            </Box>
        </Box>
        </>
    );
};

