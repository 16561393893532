import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { WeddingAdminLayout } from '../pages/administration/WeddingAdminLayout';
import { Dashboard } from '../pages/administration/Dashboard';
import { WelcomePage } from '../pages/administration/WelcomePage';
import { CreateWeddingLayout } from '../pages/create/CreateWeddingLayout';
import { Invitations } from '../pages/administration/Invitations';
import { Information } from '../pages/administration/Information';
import { Description } from '../pages/administration/Description';
import { Gifts } from '../pages/administration/Gifts';
import { MainSettings } from '../pages/administration/MainSettings';
import { AgeOptions } from '../pages/administration/AgeOptions';
import { DressCode } from '../pages/administration/DressCode';
import { Overnight } from '../pages/administration/Overnight';
import { Performances } from '../pages/administration/Performances';
import { Arrival } from '../pages/administration/Arrival';
import { Lists } from '../pages/administration/Lists';

const AdminRoutes: React.FC = () => {
    return (
            <Routes>
                <Route path="/create" element={<CreateWeddingLayout />} />
                <Route path="/:weddingId/agecategories" element={
                    <WeddingAdminLayout>
                        <AgeOptions />
                    </WeddingAdminLayout>
                } />
                <Route path="/:weddingId/settings" element={
                    <WeddingAdminLayout>
                        <MainSettings />
                    </WeddingAdminLayout>
                } />
                <Route path="/:weddingId/invitations" element={
                    <WeddingAdminLayout>
                        <Invitations />
                    </WeddingAdminLayout>
                } />
                <Route path="/:weddingId/timeline" element={
                    <WeddingAdminLayout>
                        <Information />
                    </WeddingAdminLayout>
                } />
                <Route path="/:weddingId/description" element={
                    <WeddingAdminLayout>
                        <Description />
                    </WeddingAdminLayout>
                } />
                <Route path="/:weddingId/giftlist" element={
                    <WeddingAdminLayout>
                        <Gifts />
                    </WeddingAdminLayout>
                } />
                <Route path="/:weddingId/dresscode" element={
                    <WeddingAdminLayout>
                        <DressCode />
                    </WeddingAdminLayout>
                } />
                <Route path="/:weddingId/overnight" element={
                    <WeddingAdminLayout>
                        <Overnight />
                    </WeddingAdminLayout>
                } />
                <Route path="/:weddingId/performances" element={
                    <WeddingAdminLayout>
                        <Performances />
                    </WeddingAdminLayout>
                } />
                <Route path="/:weddingId/arrival" element={
                    <WeddingAdminLayout>
                        <Arrival />
                    </WeddingAdminLayout>
                } />
                <Route path="/:weddingId/lists" element={
                    <WeddingAdminLayout>
                        <Lists />
                    </WeddingAdminLayout>
                } />
                <Route path="/:weddingId" element={
                    <WeddingAdminLayout>
                        <Dashboard />
                    </WeddingAdminLayout>
                } />
                <Route path="/" element={
                    <WeddingAdminLayout>
                        <WelcomePage />
                    </WeddingAdminLayout>
                } />
            </Routes>
    );
};

export default AdminRoutes;
