import { Box, Typography, ToggleButtonGroup, ToggleButton } from "@mui/material";
import { green, red } from "@mui/material/colors";
import React from "react";
import CheckIcon from '@mui/icons-material/Check';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import { Guest } from "../../../utils/Wedding";
import CloseIcon from '@mui/icons-material/Close';

interface IProps {
    guest: Guest;
    onChange: (id: number)=>void;
}

export const WillAttend: React.FC<IProps> = ({guest, onChange})=>{

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            gap={0}
        >
            <Typography variant="body2" textAlign="center" sx={{color: 'text.secondary', mb: 0.5}}>Bist du dabei?</Typography>
            <ToggleButtonGroup
                value={guest.willAttend}
                exclusive
                onChange={(_, selectedValue) => {
                    if (selectedValue !== null) {
                        onChange(guest.id!);
                    }
                }}
                size="small"
                aria-label="attendance confirmation"
            >
                <ToggleButton
                    value={true}
                    aria-label="Confirm attendance"
                    sx={{
                        backgroundColor: guest.willAttend ? green[400] : undefined,
                        color: guest.willAttend ? '#fff' : undefined,
                        '&.Mui-selected': {
                            backgroundColor: green[500],
                            color: '#fff',
                            '&:hover': {
                                backgroundColor: green[300],
                            },
                        },
                        textTransform: "none"
                    }}
                >
                    <CheckIcon />
                    <Typography variant="body2" textAlign="center">Ja</Typography>
                </ToggleButton>
                <ToggleButton
                    value={false}
                    aria-label="Decline attendance"
                    sx={{
                        backgroundColor: !guest.willAttend ? red[400] : undefined,
                        color: !guest.willAttend ? '#fff' : undefined,
                        '&.Mui-selected': {
                            backgroundColor: red[500],
                            color: '#fff',
                            '&:hover': {
                                backgroundColor: red[300],
                            },
                        },
                        textTransform: "none"
                    }}
                >
                    <CloseIcon />
                    <Typography variant="body2" textAlign="center">Nein</Typography>
                </ToggleButton>
            </ToggleButtonGroup>
        </Box>
    )
}