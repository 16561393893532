import React, { useEffect, useState } from "react"
import { useWeddingAdmin } from "../../context/WeddingAdminProvider"
import { Box, Button, Collapse, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useMediaQuery, useTheme } from "@mui/material"
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Link } from "react-router-dom";
import { ResponsiblePaper } from "../wedding/components/ResponsiblePaper";
import { Gauge, gaugeClasses } from "@mui/x-charts";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { AgeType, AgeTypeList, Group, Guest } from "../../utils/Wedding";
import ElderlyIcon from '@mui/icons-material/Elderly';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import ChildCareIcon from '@mui/icons-material/ChildCare';
import { TableGroups } from "./components/TableGuests/TableGroups";

export const Dashboard = ()=>{
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const { wedding, weddingId } = useWeddingAdmin()
    const [willAttendSum, setWillAttendSum] = useState<number>(0)
    const [totalGuests, setTotalGuests] = useState<number>(0)
    const [openRows, setOpenRows] = useState<{ [key: string]: boolean }>({});

    const handleToggle = (id: number) => {
        setOpenRows(prev => ({ ...prev, [id]: !prev[id] }));
    };

    useEffect(() => {
        if (wedding) {
            const tempWillAttendSum = (wedding.groups || []).reduce((acc, group) => {
                let tempCounter = 0;
                (group.guests || []).forEach((guest) => {
                    if (guest?.willAttend) tempCounter += 1;
                })
                return acc + tempCounter
            }, 0)
            setWillAttendSum(tempWillAttendSum)

            const tempTotalGuests = (wedding.groups || []).reduce((acc, group) => {
                return acc + (group?.guests?.length || 0)
            }, 0)
            setTotalGuests(tempTotalGuests)
        }
    }, [wedding]);

    return (
        <>
            <Box display={"inline-flex"} gap={2} mx={isMobile ? '0.5em' : '1em'} mt={"2em"}>
                <Link to={`/${weddingId}`}>
                    <Button variant="contained" color="primary" sx={{display: "inline-flex", gap: 1}}>
                        <OpenInNewIcon />
                        View wedding
                    </Button>
                </Link>
            </Box>
            <ResponsiblePaper autoMargin fullWidth widthReduction={isMobile ? '1em' : '2em'} elevation={3} style={{marginTop: "2em"}}>
                <Box display={"inline-flex"} gap={5} flexWrap={"wrap"}>
                    <Box 
                        width={"fit-content"}
                        height={"165px"}
                        maxWidth={"175px"}
                        display={"flex"}
                        flexDirection={"column"}
                        gap={1}
                        alignItems={"center"}
                        justifyContent={"center"}
                    >
                        <Typography>Attendance</Typography>
                        <Gauge
                            value={willAttendSum}
                            valueMax={totalGuests}
                            startAngle={-110}
                            endAngle={110}
                            sx={{
                                [`& .${gaugeClasses.valueText}`]: {
                                    fontSize: 25,
                                    transform: 'translate(0px, 0px)',
                                },
                            }}
                            text={({ value, valueMax }) => `${value} / ${valueMax}`}
                        />
                    </Box>
                </Box>
                <TableGroups />
            </ResponsiblePaper>
        </>
    )
}