import { Box, Button, Card, CardContent, Typography, IconButton, AvatarGroup, Avatar, Input, Divider, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useMediaQuery, useTheme } from "@mui/material"
import React, { useState, useEffect } from "react"
import { Group, Guest } from "../../../utils/Wedding"
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useWeddingAdmin } from "../../../context/WeddingAdminProvider"
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import { QRCodeCanvas, QRCodeSVG } from "qrcode.react";
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import DownloadIcon from '@mui/icons-material/Download';
import { ConfirmCancelModal } from "./ConfirmCancelModal";

interface IProps {
    group: Group;
    onRemove: ()=>void;
    onSave: (newName: string, guests: Guest[])=>void;
    isAnythingEditing: boolean;
    setIsAnythingEditing: (arg: boolean)=>void;
    downloadQrCode: (groupId?: number)=>void;
    color: string;
}

export const SingleInvitation: React.FC<IProps> = ({color, group, onRemove, onSave, isAnythingEditing, setIsAnythingEditing, downloadQrCode})=>{
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const { weddingId, alertSnack } = useWeddingAdmin()
    const [guests, setGuests] = useState<Guest[]>(group?.guests || [])
    const [newName, setNewName] = useState<string>(group.name)
    const [isEditing, setIsEditing] = useState<boolean>(false)
    const [modalRemove, setModalRemove] = useState<boolean>(false)
    const [modalQrcode, setModalQrcode] = useState<boolean>(false)

    
    useEffect(() => {
        setGuests(group?.guests || []);
        setNewName(group.name);
    }, [group]);

    const handleAddGuest = () => {
        const newGuest: Guest = { name: `Person ${(guests?.length || 0) + 1}`, willAttend: false }
        setGuests(prev => [...prev, newGuest])
    };
    
    const handleRemoveGuest = () => {
        if (guests.length && guests.length < 2) return
        setGuests(prev => prev.sort((a, b)=> a.name.localeCompare(b.name)).slice(0, -1));
    };

    const handleSave = ()=>{

        // Fix provisorio, remover no futuro
        if (guests.length === 1){
            if (guests[0].name !== "Person 1"){
                setGuests([{ name: "Person 1" }])
                onSave(newName, [{ name: "Person 1", willAttend: false }])
                setIsEditing(false)
                setIsAnythingEditing(false)
                return
            }
        }

        onSave(newName, guests)
        setIsEditing(false)
        setIsAnythingEditing(false)
    }

    const handleCancelChanges = ()=>{
        setIsAnythingEditing(false)
        setIsEditing(false)
        setNewName(group.name)
        setGuests(group?.guests || [])
    }

    const handleStartEdit = ()=>{
        if (isAnythingEditing){
            alertSnack({text: "Already editing invitation", type: "warning"})
            return
        }
        setIsAnythingEditing(true)
        setIsEditing(true)
        setNewName(group.name)
    }

    return (
        <Box sx={{ width: isMobile ? 280 : 350 }}>
            <Card variant="outlined">
                <CardContent>
                    <Box display={'inline-flex'} justifyContent={'space-between'} sx={{width: '100%'}}>
                        {isEditing ? 
                        <Box display={"inline-flex"} justifyContent={'space-between'} width={"100%"}>
                            <Input value={newName} onChange={(e)=>setNewName(e.target.value)} sx={{width: "100%"}}/>
                            <Box display={"inline-flex"} gap={1}>
                                <IconButton onClick={handleCancelChanges} size="small">
                                    <CloseIcon />
                                </IconButton>
                                <IconButton onClick={handleSave} size="small">
                                    <SaveIcon />
                                </IconButton>
                            </Box>
                        </Box>
                        :
                        <Box display={"inline-flex"} justifyContent={'space-between'} width={"100%"}>
                            <Typography variant="h6" component="div" textOverflow={"ellipsis"} noWrap>{group.name}</Typography>
                            <Box display={"inline-flex"} gap={1}>
                                <IconButton size="small" onClick={handleStartEdit}>
                                    <EditIcon />
                                </IconButton>
                                <IconButton size="small" onClick={()=>setModalRemove(true)}>
                                    <DeleteForeverIcon />
                                </IconButton>
                            </Box>
                        </Box>
                        }
                    </Box>
                    <Divider orientation="horizontal" sx={{my: 1.5}}/>
                    <Box display={"inline-flex"} alignItems={"center"} justifyContent={"space-between"} gap={2} width={"100%"}>
                        <Box display={"inline-flex"} alignItems={"center"} gap={1} style={{ height: "35px" }}>
                            <Typography sx={{ color: 'text.secondary', mr: 0.5 }} textAlign={"center"}>{group.entryCode}</Typography>
                            <QRCodeCanvas
                                fgColor={color}
                                id={`qrcode-${group.id}`}
                                style={{ height: "100%", width: "auto" }} 
                                value={`https://liebeimblick.wedding/${weddingId}?code=${group.entryCode}`} 
                                level="M"
                                size={256}
                                bgColor="transparent"
                            />
                            <IconButton size="small" onClick={()=>setModalQrcode(true)}>
                                <ZoomInIcon />
                            </IconButton>
                            <IconButton size="small" onClick={()=>downloadQrCode(group.id)}>
                                <DownloadIcon />
                            </IconButton>
                        </Box>
                        <Box display={'flex'} flexDirection={"column"} justifyContent={'space-between'} sx={{width: '100%'}}>
                            <Box display={'inline-flex'} alignItems={'center'} justifyContent={"flex-end"}>
                                {/* <Typography variant="body2" sx={{ color: 'text.secondary' }}>Number of guests:</Typography> */}
                                {isEditing && <>
                                    <IconButton size="small" onClick={handleAddGuest}>
                                        <AddIcon />
                                    </IconButton>
                                    <IconButton size="small" onClick={handleRemoveGuest}>
                                        <RemoveIcon />
                                    </IconButton>
                                </>}
                            </Box>
                            <AvatarGroup max={isMobile ? 3 : 5} sx={{"& .MuiAvatarGroup-avatar": { width: 24, height: 24, fontSize: 14 }}}>
                                {guests?.map((guest, iGuest)=>{
                                    return <Avatar key={iGuest} sx={{ width: 24, height: 24 }} alt="" src="/broken-image.jpg"/>
                                })}
                            </AvatarGroup>
                        </Box>
                    </Box>
                </CardContent>
            </Card>
            <ConfirmCancelModal 
                open={modalRemove} 
                onClose={()=>setModalRemove(false)} 
                onConfirm={()=>{
                    setModalRemove(false)
                    onRemove()
                }}
                title="Delete invitation?"
                subtitle="This action is irreversible"
            />
            <Dialog
                open={modalQrcode}
                onClose={()=>setModalQrcode(false)}
            >
                <DialogContent>
                    <QRCodeSVG 
                        fgColor={color}
                        style={{ height: "100%", width: "auto" }} 
                        value={`https://liebeimblick.wedding/${weddingId}?code=${group.entryCode}`} 
                        level="M"
                        size={256}
                    />
                </DialogContent>
            </Dialog>
        </Box>
    )
}