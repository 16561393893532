import React, { useEffect, useRef, useState } from "react"
import { useWeddingAdmin } from "../../context/WeddingAdminProvider"
import { Button, Grid2, Typography, Box, useMediaQuery, useTheme, Divider, Card, CardContent } from "@mui/material";
import { Group, Guest } from "../../utils/Wedding";
import { ResponsiblePaper } from "../wedding/components/ResponsiblePaper";
import { SingleInvitation } from "./components/SingleInvitation";
import AddIcon from '@mui/icons-material/Add';
import generateDisplayUrl from "../../utils/generateDisplayUrl";
import { AddInvitation } from "./components/AddInvitation";
import JSZip from "jszip";
import saveAs from "file-saver";
import DownloadIcon from '@mui/icons-material/Download';
import { SketchPicker } from "react-color";
import FormatColorFillIcon from '@mui/icons-material/FormatColorFill';
import { QRCodeCanvas } from "qrcode.react";


export const Invitations: React.FC<any> = ()=>{
    const { wedding, weddingId, alertSnack, refetch, setWedding } = useWeddingAdmin()
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const [groups, setGroups] = useState<Group[]>(wedding?.groups || [])            // Copy from state weddings, easier to handle
    const [isAnythingEditing, setIsAnythingEditing] = useState<boolean>(false)      // Global, only 1 invitation at same time
    const [newGroup, setNewGroup] = useState<Group>({
        name: `Invitation ${(wedding?.groups && wedding?.groups.length + 1) || 1}`,
        entryCode: generateDisplayUrl(),
        guests: [{name: "Person 1", willAttend: false}]
    })   
    const pickerRef = useRef(null);
    const [open, setOpen] = useState<boolean>(false);
    const [color, setColor] = useState<string>("#000000");

    const handleClickOutside = (event: MouseEvent) => {
        if (pickerRef.current && (pickerRef.current as Node).contains(event.target as Node)) {
            return;
        }
        setOpen(false);
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(()=>{
        setGroups(wedding?.groups || [])
        setNewGroup({
            name: `Invitation ${(wedding?.groups && wedding?.groups.length + 1) || 1}`,
            entryCode: generateDisplayUrl(),
            guests: [{name: "Person 1", willAttend: false}]
        })
    },[wedding?.groups])

    const handleAddGroup = ()=>{
        if (!newGroup.name.trim() || !newGroup.guests?.length){
            alertSnack({text: "Invalid name", type: "warning"})
            return
        }

        const invitationsLength = groups.length || 0  // save for now

        fetch(`/api/weddings/${weddingId}/groups`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(newGroup)
        }).then((response)=>{
            if (response.ok){
                return response.json()
            }
            alertSnack({text: "Error", type: "error"})
        }).then((data)=>{
            const id = data.groupId
            setGroups(prev => [...prev, {...newGroup, id: id}])
            alertSnack({text: "Success", type: "success"})
        }).catch((err)=>{
            alertSnack({text: "Error - Unknown", type: "error"})

        }).finally(()=>{
            setNewGroup({
                name: `Invitation ${invitationsLength + 2}`,
                entryCode: generateDisplayUrl(),
                guests: [{name: "Person 1", willAttend: false}]
            })
        })
    }

    const handleRemoveGroup = (groupId?: number) => {
        if (!groupId) return
        fetch(`/api/weddings/${weddingId}/groups/${groupId}`, {
            method: "DELETE",
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((response)=>{
            if (response.ok) {
                setGroups(prevGroups => prevGroups.filter((g) => g.id !== groupId));
                alertSnack({text: "Removed", type: "success"})
                return
            }
            alertSnack({text: "Error", type: "error"})
        }).catch((err)=>{
            alertSnack({text: "Error - Unknown", type: "error"})
        })
        
    };

    const handleUpdateGroup = (newName: string, guests: Guest[], oldGroup: Group)=>{
        if (!newName || guests.length < 1) return
        const updatedGroup: Group = {
            ...oldGroup, 
            name: newName.trim(),
            guests 
        };

        fetch(`/api/weddings/${weddingId}/groups/${oldGroup.id}`, {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(updatedGroup)
        }).then((response)=>{
            if (response.ok) {
                setGroups((prevGroups) =>
                    prevGroups.map((group) =>
                        group.id === updatedGroup.id ? updatedGroup : group
                    )
                );
                alertSnack({ text: "Update Success", type: "success" });
                return
            }
            alertSnack({text: "Error", type: "error"})
        }).catch((err)=>{
            refetch()
            alertSnack({text: "Error - Unknown", type: "error"})
        })
    }

    const handleAddNewInvitationGuest = () => {
        setNewGroup((prevGroup) => ({
            ...prevGroup,
            guests: [
                ...(prevGroup.guests || []),
                { name: `Person ${(prevGroup.guests?.length || 0) + 1}`, willAttend: false },
            ],
        }));
    };
    
    const handleRemoveNewInvitationGuest = () => {
        setNewGroup((prevGroup) => {
            const currentGuests = prevGroup.guests || [];
            if (currentGuests.length <= 1) return prevGroup;
            return {
                ...prevGroup,
                guests: currentGuests.sort((a, b)=> a.name.localeCompare(b.name)).slice(0, -1),
            };
        });
    };
    
    const handleChangeNameNewInvitation = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setNewGroup((prevGroup) => ({
            ...prevGroup,
            name: e.target.value,
        }));
    };

    const handleRegenerateCode = ()=>{
        const newCode = generateDisplayUrl()
        setNewGroup(prev => ({...prev, entryCode: newCode}))
    }

    const downloadSingleQRCode = (groupId?: number) => {
        if (!groupId) return
        const g: Group | null = groups.find(g => g.id === groupId) || null
        if (!g) return
        const canvas = document.getElementById(`qrcode-${groupId}`) as HTMLCanvasElement;
        if (!canvas) return
        const pngUrl = canvas
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = `${g.name} - ${g.entryCode}.png`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    const downloadAllQRCodes = async () => {
        const zip = new JSZip();
        const errors = []
    
        if (wedding?.groups?.length) {
            for (const group of wedding.groups) {
                const qrCodeCanvas = document.querySelector(
                    `#qrcode-${group.id}`
                ) as HTMLCanvasElement;
    
                if (!qrCodeCanvas) {
                    errors.push(group.entryCode)
                    continue;
                }
    
                const imageData = qrCodeCanvas.toDataURL("image/png");
    
                zip.file(`${group.entryCode} - ${group.name}.png`, imageData.split(",")[1], {
                    base64: true,
                });
            }
    
            const content = await zip.generateAsync({ type: "blob" });
            saveAs(content, `${weddingId}_qrcodes.zip`);

            if (errors.length > 0){
                alertSnack({text: `Error(s) with: ${errors.join(', ')}`, type: "warning"})
            }
        }
    };

    const downloadWeddingQRCode = ()=>{
        if (!wedding) return
        const canvas = document.getElementById("qrcode-wedding") as HTMLCanvasElement;
        if (!canvas) return
        const pngUrl = canvas
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = `${wedding?.name || "qrcode"}.png`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    }
    
    return (
        <>
        <ResponsiblePaper autoMargin fullWidth widthReduction={isMobile ? '1em' : '2em'} elevation={3} style={{marginTop: "2em", height: "calc(100% - 2em)"}}>
            <Grid2 size={12}>
                <Typography variant="h6" sx={{mb: 1}}>Invitations</Typography>
                <Card variant="outlined" sx={{width: "fit-content"}}>
                    <CardContent>
                            <Box>
                            <Typography variant="subtitle1" color="textSecondary" sx={{width: "fit-content"}}>Add new invitation</Typography>
                            <AddInvitation 
                                group={newGroup}
                                onGuestAdd={handleAddNewInvitationGuest}
                                onGuestRemove={handleRemoveNewInvitationGuest}
                                onNameChange={handleChangeNameNewInvitation}
                                onRegenerateQRCode={handleRegenerateCode}
                            >
                                <Button color={"primary"} variant="contained" onClick={handleAddGroup}><AddIcon />Add invitation</Button>
                            </AddInvitation>
                        </Box>
                    </CardContent>
                </Card>
                
                <Divider orientation="horizontal" variant="fullWidth" sx={{mt: 3, mb: 3}}/>
                
                <Typography variant="subtitle1" color="textSecondary">All invitations</Typography>

                <Box display={"inline-flex"} flexWrap={"wrap"} gap={2}>
                    <Button size="small" variant="contained" color="secondary" onClick={downloadWeddingQRCode}><DownloadIcon />Download Wedding QRCode</Button>
                    <Button size="small" variant="contained" color="secondary" onClick={downloadAllQRCodes}><DownloadIcon />Download all QRCodes</Button>
                    <Box sx={{ position: "relative", display: "inline-block" }}>
                        <Button size="small" variant="outlined" color="secondary" onClick={() => setOpen(!open)}><FormatColorFillIcon /> Change color</Button>
                        {open && (
                            <Box
                                ref={pickerRef}
                                sx={{
                                    position: "absolute",
                                    zIndex: 2,
                                    top: "100%",
                                    mt: 1,
                                }}
                            >
                            <SketchPicker
                                color={color}
                                onChangeComplete={(sColor)=>setColor(sColor.hex)}
                                disableAlpha
                            />
                            </Box>
                        )}
                    </Box>
                </Box>

                <Box display={'flex'} flexDirection={'row'} flexWrap={"wrap"} gap={2} sx={{overflowY: 'auto', maxHeight: '100%', mt: 2}}>
                    {groups.sort((a, b) => a.name.localeCompare(b.name))?.map((group, iGroup)=>{
                        return (
                            <SingleInvitation key={iGroup} 
                                downloadQrCode={downloadSingleQRCode}
                                group={group} 
                                onRemove={()=>handleRemoveGroup(group.id)}
                                onSave={(newName, guests)=>handleUpdateGroup(newName, guests, group)}
                                setIsAnythingEditing={setIsAnythingEditing}
                                isAnythingEditing={isAnythingEditing}
                                color={color}
                            />
                        )
                    })}
                </Box>
            </Grid2>
            <Box display={"none"}>
                <QRCodeCanvas
                    id={"qrcode-wedding"}
                    fgColor={color}
                    bgColor="transparent"
                    style={{ height: "100%", width: "auto" }} 
                    value={`https://liebeimblick.wedding/${weddingId}`} 
                    level="M"
                    size={256}
                />
            </Box>
        </ResponsiblePaper> 
        </>
    )
}