import React, { useState } from "react"
import { useWeddingAdmin } from "../../context/WeddingAdminProvider"
import { Button, Grid2, Typography, Box, useMediaQuery, useTheme, Divider, ToggleButtonGroup, ToggleButton, TextField, IconButton, ListItem, ListItemButton, ListItemText } from "@mui/material";
import { AgeOption, AgeType, AgeTypeList, Wedding } from "../../utils/Wedding";
import { ResponsiblePaper } from "../wedding/components/ResponsiblePaper";
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ElderlyIcon from '@mui/icons-material/Elderly';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import ChildCareIcon from '@mui/icons-material/ChildCare';
import { ConfirmCancelModal } from "./components/ConfirmCancelModal";

export const AgeOptions: React.FC<any> = ()=>{
    const { wedding, weddingId, alertSnack, refetch, setWedding } = useWeddingAdmin()
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const [selectedType, setSelectedType] = useState<AgeType | null>(null)
    const [description, setDescription] = useState<string>('')
    const [modalRemove, setModalRemove] = useState<boolean>(false)
    const [selectedToRemove, setSelectedToRemove] = useState<number>(0)
    
    const handleAdd = ()=>{
        if (!selectedType){
            alertSnack({text: "Please select an age category", type: "warning"})
            return
        }

        // Block more as one / category
        if (wedding?.ageOptions.find(o => o.type === selectedType)) return

        const ageOption: AgeOption = {
            description,
            type: selectedType
        } 
        fetch(`/api/weddings/${weddingId}/ageoptions`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(ageOption)
        }).then((response)=>{
            if (response.ok) return response.json()
            return
        }).then((dataResponse)=>{
            if (dataResponse?.ageOptionId){
                // Push AgeOption to Wedding
                const newAgeOption: AgeOption = {...ageOption, id: dataResponse.ageOptionId}
                const updatedWedding: Wedding | null = wedding
                ? {
                    ...wedding,
                    ageOptions: [...(wedding.ageOptions || []), newAgeOption],
                } : null;
                setWedding(updatedWedding);
                alertSnack({text: "Success", type: "success"})
                return
            }
            // Error, refetch
            refetch()
        }).catch((err)=>{
            alertSnack({text: "Error", type: "error"})
        }).finally(()=>{
            setSelectedType(null)
            setDescription("")
        })
    }

    const handleTypeChangeAdd = (e: React.MouseEvent<HTMLElement, MouseEvent>, value: AgeType | null) => {
        value && setSelectedType(value)
    } 

    const handleChangeDescription = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>)=>{
        setDescription(e.target.value)
    }

    const handleRemoveAgeOption = (id?: number) => {
        if (!id) return
        fetch(`/api/weddings/${weddingId}/ageoptions/${id}`, {
                method: "DELETE",
                headers: {
                    'Content-Type': 'application/json'
                }
        }).then((response)=>{
            if (response.ok) {
                const oldAgeOption = wedding?.ageOptions.find(ao => ao.id === id);
                if (!oldAgeOption) {
                    refetch();
                    return;
                }
    
                const newAgeOptions = wedding?.ageOptions.filter(ao => ao.id !== id) || [];
                const updatedWedding: Wedding | null = wedding
                    ? {
                          ...wedding,
                          ageOptions: newAgeOptions,
                      }
                    : null;
    
                setWedding(updatedWedding);
                alertSnack({text: "Added", type: "success"})
            } else {
                refetch();
            }
        }).catch((err)=>{
            alertSnack({text: "Error", type: "error"})
        })
    }
    
    // Not used for now
    const handleTypeChange = (id: number | undefined, newType: AgeType) => {
        if (!id) return
        fetch(`/api/weddings/${weddingId}/ageoptions/${id}/type`, {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ type: newType })
        }).then((response)=>{
            if (response.ok){
                const oldAgeOption: AgeOption | undefined = wedding?.ageOptions.find(a => a.id === id)

                if (!oldAgeOption){
                    refetch()
                    return
                }

                const updatedAgeOptions: AgeOption[] = wedding?.ageOptions.map(ao =>
                    ao.id === id ? { ...ao, type: newType } : ao
                ) || [];
            
                const updatedWedding: Wedding | null = wedding ? {...wedding, ageOptions: updatedAgeOptions, } : null;
                setWedding(updatedWedding);
                alertSnack({text: "Success", type: "success"})
                return
            }
            // Error, refetch
            refetch()
        }).catch((err)=>{
            alertSnack({text: "Error", type: "error"})
        }).finally(()=>{
            
        })
    }
    
    return (
        <>
        <ResponsiblePaper autoMargin fullWidth widthReduction={isMobile ? '1em' : '2em'} elevation={3} style={{marginTop: "2em", height: "100%"}}>
            <Grid2 size={12} width={"100%"}>
                <Typography variant="h6">Age Categories</Typography>
                <Typography variant="caption">Here you can define the age categories that each guest can choose from. Useful for knowing how to calculate the value and quantity for the restaurant in the future.</Typography>
                
            </Grid2>
            <Box display={"inline-flex"} marginTop={"1.5em"} gap={2} flexWrap={"wrap"}  width={'100%'} maxWidth={1000} minWidth={250}>
                <TextField
                    label="Description"
                    name="ageDescription"
                    value={description}
                    onChange={handleChangeDescription}
                    size="small"
                    placeholder="16 years or older"
                    sx={{minWidth: "100px"}}
                />
                <ToggleButtonGroup size="small" exclusive value={selectedType} onChange={handleTypeChangeAdd}>
                    <ToggleButton disabled={!!(wedding?.ageOptions?.find(o => o?.type === AgeTypeList.ERWACHSENE.key)) || false} 
                        size="small" value={AgeTypeList.ERWACHSENE.key}><ElderlyIcon />{!isMobile && AgeTypeList.ERWACHSENE.label}</ToggleButton>
                    <ToggleButton disabled={!!(wedding?.ageOptions?.find(o => o?.type === AgeTypeList.KIND.key)) || false} 
                        size="small" value={AgeTypeList.KIND.key}><EmojiPeopleIcon />{!isMobile && AgeTypeList.KIND.label}</ToggleButton>
                    <ToggleButton disabled={!!(wedding?.ageOptions?.find(o => o?.type === AgeTypeList.BABY.key)) || false} 
                        size="small" value={AgeTypeList.BABY.key}><ChildCareIcon />{!isMobile && AgeTypeList.BABY.label}</ToggleButton>
                </ToggleButtonGroup>

                <Button color={"primary"} variant="contained" onClick={handleAdd}><AddIcon />Add</Button>
            </Box>
            <Divider orientation="horizontal" sx={{mb: 1.5, mt: 1.5, width: "100%"}}/>
            <Box display={'flex'} flexDirection={'row'} flexWrap={"wrap"} gap={2} sx={{overflowY: 'auto', maxHeight: '100%'}}  width={'100%'} maxWidth={1000} minWidth={250}>
                {wedding?.ageOptions?.sort((a, b) => a.type.localeCompare(b.type))?.map((option, i)=>{
                    // onChange={(e, newType)=>handleTypeChange(option.id, newType)}
                    return (
                        <ListItem key={i} sx={{minWidth: "250px"}}
                            disablePadding
                            secondaryAction={
                                <IconButton onClick={()=>{
                                    setSelectedToRemove(option.id!)
                                    setModalRemove(true)
                                }} edge="end" aria-label="delete">
                                    <DeleteIcon />
                                </IconButton>
                            }
                        >
                            <ListItemButton>
                                <ListItemText primary={<Typography sx={{whiteSpace: "wrap", textOverflow: "ellipsis", overflow: "hidden"}} >{option.description}</Typography>} />
                                <ToggleButtonGroup size="small" exclusive value={option.type}> 
                                    <ToggleButton size="small" value={AgeTypeList.ERWACHSENE.key}><ElderlyIcon />{!isMobile && AgeTypeList.ERWACHSENE.label}</ToggleButton>
                                    <ToggleButton size="small" value={AgeTypeList.KIND.key}><EmojiPeopleIcon />{!isMobile && AgeTypeList.KIND.label}</ToggleButton>
                                    <ToggleButton size="small" value={AgeTypeList.BABY.key}><ChildCareIcon />{!isMobile && AgeTypeList.BABY.label}</ToggleButton>
                                </ToggleButtonGroup>
                            </ListItemButton>
                        </ListItem>
                    )
                })}
            </Box>
        </ResponsiblePaper> 
        <ConfirmCancelModal 
            open={modalRemove} 
            onClose={()=>setModalRemove(false)} 
            onConfirm={()=>{
                setModalRemove(false)
                handleRemoveAgeOption(selectedToRemove)
            }}
            title="Remove Age Option?"
            subtitle="This action is irreversible"
        />
        </>
    )
}