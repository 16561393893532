import React from 'react'
import BackgroundImage from './BackgroundImage'
import { Box, CircularProgress } from '@mui/material'

interface IProps {

}

export const AdminLoadingPage: React.FC<IProps> = ()=>{

    return (
        <Box display={"inline-flex"} alignItems={"center"} justifyContent={"center"} margin={"auto"} height={"100dvh"} width={"100%"}>
            {/* <BackgroundImage imageUrl='/images/wallpaper2.jpg'/> */}
            <CircularProgress color="inherit" />
        </Box>
    )
}