import { Card, CardHeader, Avatar, Divider, CardContent, Box, Typography, useTheme, useMediaQuery } from '@mui/material'
import React from 'react'
import { AgeType } from '../../../utils/Wedding';
import PersonIcon from '@mui/icons-material/Person';
import { useWedding } from '../../../context/WeddingProvider';
import { AgeOptionsSelect } from './AgeOptionsSelect';
import { WillAttend } from './WillAttend';

interface IProps {
    
}

export const Guests: React.FC<IProps> = ()=>{
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const { wedding, toggleWillAttend, selectAgeOption } = useWedding()
    
    const handleChangeWillAttend = (guestId?: number)=>{
        guestId && toggleWillAttend(guestId)
    }

    const handleChangeAgeOption = (newType: AgeType, guestId?: number)=>{
        if (!newType || !guestId) return
        const groupId = wedding?.guestGroup?.id
        if (!groupId) return
        selectAgeOption(groupId, guestId, newType)
    }

    return (
        <Box display={"inline-flex"} flexWrap={"wrap"} alignItems={"center"} justifyContent={"center"}>
            {wedding?.guestGroup?.guests?.sort((a, b)=> a.name.localeCompare(b.name)).map((g, i) => {
                return (
                    <Card key={i} sx={{ minWidth: '15rem', width: "calc(100% - 1em)", maxWidth: "18rem", margin: '0.8rem', boxShadow: 3 }}>
                        <Typography sx={{mt: 1.2}} fontWeight={"bold"} variant='body2' textAlign={'center'} color={theme.palette.textColor.title}>
                            {g.name}
                        </Typography>
                        <Divider sx={{mt: 1.2, mb: 0.8, width: "100%"}}/>
                        <CardHeader
                            title={
                                <AgeOptionsSelect onChange={handleChangeAgeOption} options={wedding.ageOptions} guest={g}/>
                            }
                            avatar={
                                <Avatar alt={g.name} src="">
                                    <PersonIcon />
                                </Avatar>
                            }
                            sx={{padding: '0.5em 1em'}}
                        />
                        <Divider />
                        <CardContent>
                            <WillAttend onChange={handleChangeWillAttend} guest={g}/>
                        </CardContent>
                    </Card>
                )
            })}
        </Box>
        
    )
}