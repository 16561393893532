import { Box } from "@mui/material"
import { useWedding } from "../../../context/WeddingProvider"
import { SingleList } from "./SingleList"
import { ContributionList, ContributionOption, Wedding } from "../../../utils/Wedding"
import { useAuth } from "../../../context/AuthProvider"



interface IProps {
    
}

export const AllLists: React.FC<IProps> = ()=>{
    const { wedding, weddingId, setWedding, alertSnack } = useWedding()

    const handleRemoveItemFromList = (itemId: number, listId: number)=>{
        if (!weddingId) return
        fetch(`/api/weddings/${weddingId}/lists/${listId}/items/${itemId}`, {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((response)=>{
            if (response.ok) {
                const updatedLists = wedding?.lists.map(list => {
                    if (list.id === listId) {
                        return {
                            ...list,
                            items: list.items.filter(item => item.id !== itemId)
                        };
                    }
                    return list;
                }) || [];

                const updatedWedding = wedding ? {
                    ...wedding,
                    lists: updatedLists
                } : null;

                setWedding(updatedWedding);
                return
            }
            alertSnack({ type: "warning", text: "Fehler" })
        }).catch((err)=>{
            alertSnack({ type: "error", text: "Fehler: " + err })
        })
    }

    const handleAddItemToList = (value: string, listId?: number)=>{
        if (!weddingId) return
        if (!wedding) return
        const payload = { itemName: value }

        fetch(`/api/weddings/${weddingId}/lists/${listId}/items`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        }).then((response)=>{
            if (response.ok) {
                return response.json()
            }
        }).then((data)=>{
            if (data.itemId) {
                const oldList = wedding?.lists.find(l => l.id === listId);
                if (!oldList) {
                    alertSnack({ type: "warning", text: "Fehler" })
                    return;
                }

                const newItem: ContributionOption = {
                    id: data.itemId,
                    name: value,
                    creator: wedding?.guestGroup?.id || null
                }
    
                const updatedList: ContributionList = {
                    ...oldList,
                    items: [...oldList.items, newItem]
                };
            
                const updatedLists = wedding?.lists.map(l => 
                    l.id === listId ? updatedList : l
                );
        
                const updatedWedding: Wedding | null = wedding ? { 
                    ...wedding, 
                    lists: updatedLists 
                } : null;
        
                setWedding(updatedWedding);
            } else {
                alertSnack({ type: "warning", text: "Fehler" })
            }
        }).catch((err) => {
            alertSnack({ type: "error", text: "Fehler: " + err })
        })
    }

    return (
        <Box display={"inline-flex"} flexWrap={"wrap"} gap={"4rem"}>
            {wedding?.lists.sort((a, b)=> a.name.localeCompare(b.name)).map((l, i)=>{
                return <SingleList key={i} 
                    list={l} 
                    add={handleAddItemToList} 
                    remove={handleRemoveItemFromList} 
                    groupId={wedding?.guestGroup?.id}
                />
            })}
        </Box>
    )
}