import { useNavigate } from "react-router-dom"
import { useAuth } from "../../context/AuthProvider"
import BackgroundImage from "../../components/BackgroundImage"
import { Box, Button, Typography, useMediaQuery, useTheme } from "@mui/material"
import AddIcon from '@mui/icons-material/Add';

export const WelcomePage = ()=>{
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const navigate = useNavigate()
    const { logout } = useAuth()


    return (
        <Box width={"100%"} display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"} mt={"2em"}>
            <BackgroundImage imageUrl="/images/wallpaper2.jpg"/>
            <Box width={"100%"} display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"} my={"2em"}>
                <Box height={isMobile ? "6rem" : "8rem"} width={isMobile ? "6rem" : "8rem"}>
                    <img height={"100%"} width={"100%"} src="/images/Logo.png"/>
                </Box>
                <Typography margin={"0.2em 0 0 0"} variant="h1" textAlign={"center"} fontSize={isMobile ? '3rem' : "6rem"}
                    fontFamily={theme.palette.homepage.fontFamily} color={theme.palette.textColor.title2}>
                    Liebe im Blick
                </Typography>
                <Typography variant="h6" fontFamily={theme.palette.homepage.fontFamily} 
                    color={theme.palette.textColor.title2} fontSize={isMobile ? '1rem' : "1.25rem"}>
                    Ihr Hochzeitsplaner
                </Typography>

            </Box>
            <Button onClick={()=>navigate("/weddings/create")} variant="contained" color="primary" startIcon={<AddIcon />}>Create wedding</Button>
            <Button sx={{mt: "1em"}} onClick={()=>logout()} variant="contained" color="secondary">Logout</Button>
        </Box>
    )
}