import { createTheme } from '@mui/material/styles';

const lightTheme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: '#475c33',
        },
        secondary: {
            main: '#5c5433',
        },
        background: {
            default: '#faf8f3',
            paper: '#ffffff',
        },
        text: {
            primary: '#000000',
            secondary: '#000000',
            
        },
        navbar: {
            background: '#e5e1d3',
            text: '#484b2f',
            bgName: '#e5e1d3a8'
        },
        floatingButton: {
            background: '#acbba0',
            color: '#51644c'
        },
        description: {
            background: '#faf8f3',
            color: '#2a2a2a'
        },
        homepage: {
            fontFamily: 'Emilys Candy, Arial, sans-serif',
            color: '#000',
            bgpaper: '#faf8f3',
            fontcolorpaper: '#000000'
        },
        navbarAdmin: {
            background: '#000000'
        },
        textColor: {
            title: '#2f411e',
            title2: '#5c5433',
            subtitle: '#4d6143',
            text: '#000000'
        },
        icon: {
            primary: '#7b8b7a',
            secondary: '#acbba0'
        }
    },
});

declare module "@mui/material/styles" {
    interface Palette {
        navbar?: {
            background: string;
            text: string;
            bgName: string
        };
        floatingButton: {
            background: string;
            color: string;
        }
        description: {
            background: string;
            color: string;
        },
        homepage: {
            fontFamily: string,
            color: string,
            bgpaper: string,
            fontcolorpaper: string
        },
        navbarAdmin: {
            background: string
        }
        textColor: {
            title: string;
            subtitle: string;
            text: string;
            title2: string
        },
        icon: {
            primary: string
            secondary: string
        }
    }
    interface PaletteOptions {
        navbar?: {
            background: string;
            text: string;
            bgName: string

        };
        floatingButton: {
            background: string;
            color: string;
        }
        description: {
            background: string;
            color: string;
        },
        homepage: {
            fontFamily: string,
            color: string,
            bgpaper: string,
            fontcolorpaper: string
        },
        navbarAdmin: {
            background: string
        }
        textColor: {
            title: string;
            subtitle: string;
            text: string;
            title2: string
        },
        icon: {
            primary: string
            secondary: string

        }
    }
}
  

export default lightTheme;