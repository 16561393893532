import styled from "@emotion/styled";
import Sanitize from "../utils/Sanitize";

const SanitizedDiv = styled.div`
    max-width: 50rem;

    .ql-italic {
        font-style: italic;
    }

    .ql-underline {
        text-decoration: underline;
    }

    p {
        margin: 0
    }

    .ql-align-center {
        text-align: center;
    }

    .ql-align-right {
        text-align: right;
    }

    .ql-align-justify {
        text-align: justify;
    }

    .ql-size-small {
        font-size: 0.75em; /* 75% do tamanho padrão */
    }

    .ql-size-normal {
        font-size: 1em; /* Tamanho padrão */
    }

    .ql-size-large {
        font-size: 1.5em; /* 150% do tamanho padrão */
    }

    .ql-size-huge {
        font-size: 2.5em; /* 250% do tamanho padrão */
    }
    `;

interface IProps {
    data?: string | null
}

const HtmlDiv: React.FC<IProps> = ({data})=>{

    const sanitized = Sanitize(data)
    return (
        <SanitizedDiv style={{maxWidth: '50rem'}} dangerouslySetInnerHTML={{ __html: sanitized }} />
    )
}

export default HtmlDiv