import { useMediaQuery, AppBar, Toolbar, Box, Button, Fab, Drawer, List, ListItem, ListItemButton, ListItemText, useTheme, ListItemIcon, Avatar, Typography, Divider } from "@mui/material";
import { useState } from "react";
import { UserMenu } from "./UserMenu";
import { Menu as MenuIcon } from "@mui/icons-material";
import { Link } from "react-scroll";
import { useWedding } from "../../../context/WeddingProvider";
import HomeIcon from '@mui/icons-material/Home';
import TheaterComedyIcon from '@mui/icons-material/TheaterComedy';
import HotelIcon from '@mui/icons-material/Hotel';
import TimelineIcon from '@mui/icons-material/Timeline';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import RuleIcon from '@mui/icons-material/Rule';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import LogoutIcon from '@mui/icons-material/Logout';
import { useAuth } from "../../../context/AuthProvider";
import CheckroomIcon from '@mui/icons-material/Checkroom';
import ListIcon from '@mui/icons-material/List';

interface NavItems {
    label: string;
    link: string;
    icon: JSX.Element;
}

export const Navigation = ()=>{
    const { wedding } = useWedding()
    const { logout } = useAuth()
    const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const isTablet = useMediaQuery(theme.breakpoints.between("md", "lg"))

    const toggleDrawer = (open: boolean) => () => {
        setDrawerOpen(open);
    };

    const navItems: NavItems[] = [
        { label: 'Home', link: 'home', icon: <HomeIcon /> },
        wedding?.infos && { label: 'Tagesablauf', link: 'infos', icon: <TimelineIcon /> },
        wedding?.arrival && { label: 'Anfahrt', link: 'arrival', icon: <DirectionsCarIcon /> },
        wedding?.overnight && { label: 'Übernachtung', link: 'overnight', icon: <HotelIcon /> },
        wedding?.dressCode && { label: 'Dresscode', link: 'dresscode', icon: <CheckroomIcon /> },
        { label: 'Angaben', link: 'group', icon: <RuleIcon /> },
        (wedding?.giftOptions?.length || wedding?.giftsDescription) && { label: 'Geschenke', link: 'gifts', icon: <CardGiftcardIcon />, },
        (wedding?.lists.length !== 0 ) && { label: 'Listen', link: 'lists', icon: <ListIcon />, },
        wedding?.performances && { label: 'Beiträge', link: 'performances', icon: <TheaterComedyIcon /> },

    ].filter(Boolean) as NavItems[];

    // **** MOBILE ****
    // Sidebar
    if (isMobile){
        return (
            <>  
                <Fab 
                    sx={{
                        borderRadius: 1, 
                        backgroundColor: theme.palette.floatingButton.background,
                        height: '32px',
                        width: '32px',
                        minHeight: 0,
                        minWidth: 0,
                        display: "inline-flex",
                        alignItems: 'center',
                        justifyContent: 'center',
                        position: 'fixed',
                        left: '10px',
                        top: '10px'
                    }} 
                    onClick={toggleDrawer(true)} >
                    <MenuIcon sx={{width: '25px', height: '25px', color: theme.palette.floatingButton.color}}/>
                </Fab>
                <Drawer 
                    anchor="left" 
                    open={drawerOpen} 
                    onClose={toggleDrawer(false)}
                    PaperProps={{sx: {width: "70%"}}}
                >
                    <List sx={{paddingTop: 0}}>
                        <Box display={"flex"} flexDirection={"column"} paddingX={2} paddingY={4} gap={1.5}
                            sx={{backgroundColor: theme.palette.navbar?.bgName}}
                        >
                            <Avatar src="/favicon.ico" sx={{height: "50px", width: "50px"}}/>
                            <Typography variant="body1" color={theme.palette.textColor.title} fontWeight={"bold"}>{wedding?.name}</Typography>
                            <Typography variant="body2" sx={{paddingRight: "10px", overflow: "hidden"}} textOverflow={"ellipsis"} color={theme.palette.textColor.title}>
                                Willkommen, {wedding?.guestGroup?.name}
                            </Typography>
                        </Box>
                        {navItems.map((item, index) => (
                            <ListItem disablePadding key={index} onClick={toggleDrawer(false)}>
                                <Link key={item.label} to={item.link} spy smooth duration={500} onClick={() => setDrawerOpen(false)} style={{width: '100%'}}>
                                    <ListItemButton >
                                        <ListItemIcon sx={{fill: theme.palette.icon.primary}}>{item.icon}</ListItemIcon>
                                        <ListItemText primary={item.label} color={theme.palette.navbar?.text}/>
                                    </ListItemButton>
                                </Link>
                            </ListItem>
                        ))}
                        <Divider sx={{my: 2}}/>
                        <ListItem disablePadding key={"98"} onClick={()=>logout()}>
                            <ListItemButton >
                                <ListItemIcon sx={{fill: theme.palette.icon.primary}}><LogoutIcon /></ListItemIcon>
                                <ListItemText primary={"Abmelden"} color={theme.palette.navbar?.text}/>
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Drawer>
            </> 
        )
    }

    // Navbar
    return (
        <AppBar position="fixed" sx={{width: '100%', backgroundColor: '#ffffff00'}}>
            <Toolbar 
                sx={{
                    backgroundColor: theme.palette.navbar?.background,
                    display: isTablet ? "flex" : "inline-flex",
                    flexDirection: isTablet ? "column" : "row",
                    justifyContent: "space-between",
                }}
            >
                <UserMenu groupName={wedding?.guestGroup?.name || "Gast"}/>
                <Box 
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        width: "60%"
                    }}
                >
                    {navItems.map((item) => (
                        <Link key={item.label} to={item.link} offset={-74} spy smooth duration={500}>
                            <Button key={item.label} sx={{ color: theme.palette.navbar?.text, textTransform: 'none' }}>
                                {item.label}
                            </Button>
                        </Link>
                    ))}
                </Box>
                <Box 
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        width: "20%"
                    }}
                ></Box>
            </Toolbar>
        </AppBar>
    );
}